import React from 'react';
import ErrorPopup from '../errorPopup/ErrorPopup'
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const ErrorToastManager = ({ errorToasts, onErrorToastRemove }) => {
  const handleErrorToastClose = (id, callback) => {
    if (callback) {
      callback();
    }
    onErrorToastRemove(id);
  };

  return (
    <div>
      {errorToasts.map((error, index) => (
        <>
          <ErrorPopup
            key={error?.id || `error_${index}`}
            icon={faExclamationCircle}
            title={error.message}
            onClose={() => handleErrorToastClose(error.id, error.onClose)}
            autoClose={error.autoClose}
            autoCloseTime={error.autoCloseTime}
          />
        </>
      ))}
    </div>
  );
};

export default ErrorToastManager;
