import React, { useEffect } from 'react';
import Popup from "../popup/Popup";

const ErrorPopup = (props) => {
  const { autoClose, autoCloseTime, onClose, ...restProps } = props;

  useEffect(() => {
    let timer;
    if (autoClose) {
      timer = setTimeout(() => {
        onClose?.();
      }, autoCloseTime);
    }
    
    return () => clearTimeout(timer);
  }, [autoClose, autoCloseTime, onClose]);

  return (
    <Popup onClose={onClose} showSubmitBtn={false} {...restProps} />
  );
};

export default ErrorPopup;