import React, { useRef } from 'react';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../styles/offlineBatchSync.css';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import SyncHandler from '../../controller/syncHandler/SyncHandler';
import Configs from '../../Configs';

const Report = function () {
  const history = useHistory();
  const { t } = useTranslation();
  const responseEventRef = useRef(JSON.parse(SyncHandler.getSyncData()));

  const onclickHandler = (
    eventId,
    sessionId,
    eventName,
    eventCode,
    sessionDate,
    sessionTime,
  ) => {
    history.push(Configs.REPORT_DETAILS.PATH, {
      eventId,
      sessionId,
      eventName,
      eventCode,
      sessionDate,
      sessionTime,
    });
  };

  // back to setting pg
  const backHandler = () => {
    history.push(Configs.SETTING.PATH);
  };

  return (
    <div className='offlineSync-form'>
      <div className='offlineSync-container'>
        <div className='offlineSync-top'>
          <div
            className='back-btn'
            onClick={backHandler}
            onKeyPress={backHandler}
            role='button'
            tabIndex='0'
          >
            {t('report_backText')}
          </div>
          <div className='offlineSync-details'>{t('report_headerText')}</div>
        </div>
        <div className='offlineSync-form-box'>
          {responseEventRef.current && responseEventRef.current.map((item) => (
            <React.Fragment key={item._id}>
              {item.sessions.map((sessionItem) => (
                <div
                  key={sessionItem._id}
                  className='offlineSync-form-title'
                  role='button'
                  tabIndex='0'
                    onClick={() => onclickHandler(
                        item._id,
                        sessionItem._id,
                        item.eventName,
                        item.eventCode,
                        moment(sessionItem.startDate).tz(item.timezone).format('D MMM YYYY'),
                        moment(sessionItem.startDate).tz(item.timezone).format('h:mma'),
                      )
                    }
                    onKeyPress={() =>
                      onclickHandler(
                        item._id,
                        sessionItem._id,
                        item.eventName,
                        item.eventCode,
                        moment(sessionItem.startDate).tz(item.timezone).format('D MMM YYYY'),
                        moment(sessionItem.startDate).tz(item.timezone).format('h:mma'),
                      )
                    }
                >
                  {item.eventName}
                    {' ('}
                    {item.eventCode}
                    {')'}
                  {', '}
                    {moment(sessionItem.startDate).tz(item.timezone).format('D MMM YYYY')}
                  {', '}
                    {moment(sessionItem.startDate).tz(item.timezone).format('h:mma')}
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Report;
