export async function validateBarcodeAttributes(
  admissionRuleCriteria,
  barcodeAttributes,
  sessionId,
  error,
) {
  let attributeError;
  const groupByAttribute = admissionRuleCriteria.reduce((group, criteria) => {
    const { paramKey } = criteria;
    group[paramKey] = group[paramKey] ?? [];
    group[paramKey].push(criteria);
    return group;
  }, {});

  const keys = Object.keys(groupByAttribute);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (key !== 'SESSION') {
      const barcodeAttribute = barcodeAttributes.find(
        (attribute) => attribute.attributeType == key,
      );
      if (barcodeAttribute) {
        for (let x = 0; x < groupByAttribute[key].length; x++) {
          if (groupByAttribute[key][x].paramValue == barcodeAttribute.attributeId || groupByAttribute[key][x].paramValue == 'ALL' || groupByAttribute[key][x].paramValue == null || groupByAttribute[key][x].paramValue.length <= 0) {
            attributeError = null;
            break;
          } else {
            if (error === null || error === undefined || error.priority <= 1) {
              attributeError = {
                priority: 1,
                type: 'validate_admission_barcode_invalid',
              };
            } else {
              attributeError = error;
            }
          }
        }
        if (attributeError !== null) {
          return attributeError;
        }
      } else {
        if (error === null || error === undefined || error.priority <= 1) {
          return (attributeError = {
            priority: 1,
            type: 'validate_admission_barcode_invalid',
          });
        } else {
          return (attributeError = error);
        }
      }
    } else {
      const isMatch = groupByAttribute[key].find((attribute) => attribute.paramValue === sessionId || attribute.paramValue === 'ALL' || attribute.paramValue.length <= 0 || attribute.paramValue === null);
      if (isMatch) {
        attributeError = null;
      } else {
        if (error === null || error === undefined || error.priority <= 1) {
          return (attributeError = {
            priority: 1,
            type: 'validate_admission_barcode_invalid',
          });
        } else {
          return (attributeError = error);
        }
      }
    }
  }
  return attributeError;
}
