import Cookies from 'universal-cookie';

const cookies = new Cookies();

class ValidatorModeHandler {
  static setValidatorMode(info) {
    cookies.set('VALIDATOR_MODE', info);
  }

  static validatorMode() {
    return cookies.get('VALIDATOR_MODE');
  }

  static removeValidatorMode() {
    return cookies.remove('VALIDATOR_MODE');
  }
}

export default ValidatorModeHandler;
