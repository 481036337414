import { Engine } from 'json-rules-engine';

export async function validateGateAdmissionRule(
  gate,
  gateAdmissionRule,
  eventAdmissionRule,
  error,
) {
  let gateAdmissionError;
  const engine = new Engine();

  engine.addOperator('isEqualValue', (factValue) => {
    if (factValue.toString() === gateAdmissionRule.gateTypeId.toString() &&
      gateAdmissionRule.admissionRuleId.toString() === eventAdmissionRule._id.toString()) {
      return true;
    }
    return false;
  });

  const checkGateAdmission = {
    conditions: {
      all: [
        {
          fact: 'gate',
          operator: 'isEqualValue',
          value: true,
        },
      ],
    },
    event: {
      type: 'validateGateAdmission',
    },
    priority: 10,
    onSuccess: function () {
      engine.stop();
      gateAdmissionError = null;
    },
    onFailure: function () {
      engine.stop();
      if (error === null || error === undefined || error.priority <= 2) {
        gateAdmissionError = {
          priority: 2,
          type: 'validate_wrong_gate',
        };
      } else {
        gateAdmissionError = error;
      }
    },
  };

  engine.addRule(checkGateAdmission);
  await engine.run(gate);
  return gateAdmissionError;
}
