import Cookies from 'universal-cookie';

const cookies = new Cookies();

class PairScannerAuth {
  static setScannerInfo(info) {
    cookies.set('SCANNER', info, { maxAge: 2147483647 });
  }

  static getScannerInfo() {
    return cookies.get('SCANNER');
  }

  static removeScannerInfo() {
    return cookies.remove('SCANNER');
  }

  static setScannerToken(token) {
    cookies.set('SCANNER_TOKEN', token, { maxAge: 2147483647 });
  }

  static getScannerToken() {
    return cookies.get('SCANNER_TOKEN');
  }

  static removeScannerToken() {
    return cookies.remove('SCANNER_TOKEN');
  }
}

export default PairScannerAuth;
