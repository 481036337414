class SyncDataHandler {
  static setSyncData(data) {
    localStorage.setItem('SYNC_DATA', JSON.stringify(data));
  }

  static getSyncData() {
    const syncData = localStorage.getItem('SYNC_DATA');
    return syncData;
  }

  static removeSyncData() {
    localStorage.removeItem('SYNC_DATA');
  }
}

export default SyncDataHandler;
