import { Engine } from 'json-rules-engine';

export async function validateWhitelist(barcode) {
  let error;
  const engine = new Engine();

  const checkWhitelist = {
    conditions: {
      all: [
        {
          fact: 'status',
          operator: 'notEqual',
          value: 0,
        },
      ],
    },
    event: {
      type: 'whitelistBarcode',
    },
    priority: 10,
    onFailure: function () {
      engine.stop();
      error = 'validate_barcode_blacklisted';
    },
  };

  engine.addRule(checkWhitelist);
  await engine.run(barcode);
  return error;
}
