import * as constants from '../../constants/constants';

export async function validateAdmissionType(
    barcodeStatus, // from local storage :: last data
    facts, // param :: from input 
    admissionRule,
    error,
) {

    // Admission rule type - single
    if (admissionRule.ruleType === constants.AdmissionRuleType.SINGLE_ENTRY_EXIT) {
        switch (facts.admissionType) {
            case constants.AdmissionTypeIn:
                switch (barcodeStatus.admissionType) {
                    case constants.AdmissionTypeIn:
                        if (error === null || error === undefined || error.priority <= 4) {
                            return (error = {
                                priority: 4,
                                type: 'validate_barcode_already_used',
                            });
                        } else {
                            return error;
                        }
                    case constants.AdmissionTypeOut:
                        if (error === null || error === undefined || error.priority <= 4) {
                            return (error = {
                                priority: 4,
                                type: 'validate_barcode_already_used',
                            });
                        } else {
                            return error;
                        }
                    default:
                        return null;
                }
            case constants.AdmissionTypeOut:
                switch (barcodeStatus.admissionType) {
                    case null:
                        if (error === null || error === undefined || error.priority <= 4) {
                            return (error = {
                                priority: 4,
                                type: 'validation.barcode.not.entered.yet',
                            });
                        } else {
                            return error;
                        }
                    case constants.AdmissionTypeOut:
                        if (error === null || error === undefined || error.priority <= 4) {
                            return (error = {
                                priority: 4,
                                type: 'validation.barcode.already.exited',
                            });
                        } else {
                            return error;
                        }
                    default:
                        return null;
                }
        }
    }

    if (admissionRule.ruleType === constants.AdmissionRuleType.MULTIPLE_ENTRY_EXIT_APB) {
        switch (facts.admissionType) {
            case constants.AdmissionTypeIn:
                switch (barcodeStatus.admissionType) {
                    case constants.AdmissionTypeIn:
                        if (error === null || error === undefined || error.priority <= 4) {
                            return (error = {
                                priority: 4,
                                type: 'validate_barcode_already_entered',
                            });
                        } else {
                            return error;
                        }
                    default:
                        return null;
                }
            case constants.AdmissionTypeOut:
                switch (barcodeStatus.admissionType) {
                    case null:
                        if (error === null || error === undefined || error.priority <= 4) {
                            return (error = {
                                priority: 4,
                                type: 'validate_barcode_not_entered_yet',
                            });
                        } else {
                            return error;
                        }
                    case constants.AdmissionTypeOut:
                        if (error === null || error === undefined || error.priority <= 4) {
                            return (error = {
                                priority: 4,
                                type: 'validate_barcode_already_exited',
                            });
                        } else {
                            return error;
                        }
                    default:
                        return null;
                }
        }
    }

    if (admissionRule.ruleType === constants.AdmissionRuleType.MULTIPLE_ENTRY_EXIT_NOAPB) {
        return null;
    }
}
