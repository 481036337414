import Cookies from 'universal-cookie';

const cookies = new Cookies();

class VoucherModeHandler {
  static setVoucherMode(info) {
    cookies.set('VOUCHER_MODE', info);
  }

  static voucherMode() {
    return cookies.get('VOUCHER_MODE') == 'true'? true : false;
  }

  static removeVoucherMode() {
    return cookies.remove('VOUCHER_MODE');
  }
}

export default VoucherModeHandler;
