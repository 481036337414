import Configs from './Configs';
import Auth from './controller/authHandler/AuthHandler';
import { APIError } from './errors';

const domain = '/api/v1/';

/**
 * Make an Ajax call to a specified URL using appication/json format
 * @param method , POST, GET
 * @param link The URL to connect to
 * @param func on success call back function
 * @param params What are the params to attach to the
 * @param error  error function with the params status, responseText, statusMessage
 * @param redirectError - boolean
 */

export async function fetchApi(
  method,
  target,
  func,
  params = null,
  error = null,
  redirectError = false,
  retry = 0,
  current = this
) {
  try {
    const token = Auth.getToken();
    let paramsToSend = null;
    let count = 1;
    let link = target;
    if (method === Configs.HttpMethodConstants.POST) {
      paramsToSend = JSON.stringify(params);
    } else if (method === Configs.HttpMethodConstants.GET) {
      if (params !== null) {
        Object.keys(params).forEach((key) => {
          // console.log(`Key : ${key}, Value : ${params[key]}`);
          if (count === 1) {
            link = `${link}?${key}=${params[key]}`;
            count += 1;
          } else {
            link = `${link}&${key}=${params[key]}`;
          }
        });
      }
    } else {
      paramsToSend = params;
    }

    if (token === -1) {
      // console.warn('[FetchAPI.js] - could not get token to access to API');
    }

    let apiResponse = null;

    await fetch(`${domain}${link}`, {
      method, // *Type of request GET, POST, PUT, DELETE
      cache: 'no-cache', // options like default, no-cache, reload, force-cache
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json', // request content type
        Authorization: `Bearer ${token}`,
      },
      body: paramsToSend, // Attach body with the request
      timeout: 30000,
      // mode: 'cors', // Type of mode of the request
      // credentials: 'same-origin', // options like include, *same-origin, omit
      // redirect: 'follow', // manual, *follow, error
      // referrerPolicy: 'no-referrer', // no-referrer, *client
    })
      .then(async (response) => {
        apiResponse = response;
        if (!response.ok) {
          const error = await response.json();
          throw new APIError(error?.errorMessage, apiResponse.status);
        }
        return response.json();
      })
      .then((responseData) => {
        const responseText = responseData;
        completeCall(
          apiResponse,
          apiResponse.status,
          redirectError,
          method,
          target,
          func,
          params,
          error,
          retry,
          current,
          responseText,
        );
        return responseData;
      })
      .catch((err) => {
        console.log("Error:", err)
        if(typeof error === 'function') {
          error(Configs.ERROR.MSG_401, err?.status || Configs.ERROR.CODE_401, err?.message);
        }
      });

    // return await response;
  } catch (errorFetch) {
    console.log("Error Fetch:", errorFetch)
    if(typeof error === 'function') {
      error(Configs.ERROR.MSG_401, err?.status || Configs.ERROR.CODE_401, errorFetch?.message);
    }
  }

  function completeCall(
      xhr,
      textStatus,
      redirectError,
      method,
      link,
      func,
      params,
      error,
      retry,
      current,
      responseText,
  ) {
    const retryLimit = 5;
    let tempRetry = retry;
    if (tempRetry < retryLimit && xhr.status === 0) {
      // try again
      tempRetry += 1;
      setTimeout(() => {
        fetchApi(method, link, func, params, error, redirectError, tempRetry);
      }, 1000);
      if (process.env.REACT_APP_DEV) {
        // console.log('[FetchAPI.js] - In Retry state - tryCount: ', retry, '  Max: ', retryLimit);
      }
    } else if (handleError(xhr, this, error, redirectError)) {
      // return true
      func?.(responseText, xhr.status);
    } else {
      // return false
      if(typeof error === 'function') {
        error(xhr.statusText, xhr.status);
      }
    }
  }
  /**
   * @param xhr
   * @param errorCallback - call back function with the params(status, responseText, statusMessage)
   * @param originalAjax - the original AjaxCall so that you can run the ajax call again
   * @param redirectError - boolean for whether to let FetchAPI to handle the error
   */
  function handleError(xhr, originalAjax, errorCallback = null) {
    // function handleError(xhr, originalAjax, errorCallback = null, redirectError = true) {
    let { status } = xhr;
    let responseText = '';

    if (xhr && responseText) {
      responseText = JSON.parse(responseText);
    }

    if (status < 300 && status >= 200) return true; // do not run error if the status is under 400

    if (status === 0) {
      status = 400;
      responseText = {
        httpStatus: '400',
        errorCode: 'error.generic',
      };
    }

    /**
     * Get error message from priority
     * 1) JSON response message
     * 2) Error message key (old)
     * 3) Error message key (new)
     * 4) Default error message
     */
    const statusMessage = (responseText && responseText.errorCode) || (responseText && responseText.statusMessage && responseText.statusMessage);

    responseText = JSON.stringify(responseText);
    const handleErrors = errorCallback?.(status, responseText, statusMessage);

    return handleErrors;
  }
}
