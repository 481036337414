import React from 'react';
import { useErrorHanlder } from '../../context/errorContext';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state to render fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Log error information for debugging
    this.props.showErrorNotification({
      error, 
      info
    })
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return null;
    }

    return this.props.children;
  }
}

const ErrorBoundaryWithContext = (props) => {
  const { showErrorNotification } = useErrorHanlder();
  return <ErrorBoundary {...props} showErrorNotification={showErrorNotification} />;
};

export default ErrorBoundaryWithContext;
