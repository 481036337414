import Cookies from 'universal-cookie';

const cookies = new Cookies();

class FetchLatestConfigHandler {
  static setModalLastDisplayDate(info) {
    cookies.set('MODAL_LAST_DISPLAY_DATE', info);
  }

  static getModalLastDisplayDate() {
    return cookies.get('MODAL_LAST_DISPLAY_DATE');
  }

  static removeModalLastDisplayDate() {
    return cookies.remove('MODAL_LAST_DISPLAY_DATE');
  }
}

export default FetchLatestConfigHandler;
