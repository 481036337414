import Cookies from 'universal-cookie';

const cookies = new Cookies();

class GateDataHandler {
  // gate id
  static setGateInfo(info) {
    cookies.set('GATE', info);
  }

  static getGateInfo() {
    return cookies.get('GATE');
  }

  static removeGateInfo() {
    return cookies.remove('GATE');
  }

  // gate name
  static setGateNameInfo(info) {
    cookies.set('GATE_NAME', info);
  }

  static getGateNameInfo() {
    return cookies.get('GATE_NAME');
  }

  static removeGateNameInfo() {
    return cookies.remove('GATE_NAME');
  }
}

export default GateDataHandler;
