import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BrowserMultiFormatReader, NotFoundException,DecodeHintType } from '@zxing/library';
import { AppContext } from '../../context/appContext';
import ResultDisplay from '../resultDisplay/ResultDisplay';
import Button from '../button/Button';
import EventDataHandler from '../../controller/eventHandler/EventHandler';
import GateDataHandler from '../../controller/eventHandler/GateHandler';
import OfflineBatchHandler from '../../controller/syncHandler/OfflineBatchHandler';
import ScanInHandler from '../../controller/scanHandler/ScanInHandler';
import AppModeHandler from '../../controller/appModeHandler/AppModeHandler';
import ValidatorModeHandler from '../../controller/validatorModeHandler/ValidatorModeHandler';
import { fetchApi } from '../../AjaxUtil';
import scanImg from '../../assets/images/scanborder.png';
import Configs from '../../Configs';
import * as constants from '../../constants/constants';
import '../../styles/scanner.css';
import PairScannerNameHandler from '../../controller/authHandler/PairScannerNameHandler';
import ScannerUserHandler from '../../controller/authHandler/ScannerUserHandler';
import { validate } from '../../offlineValidation/validate';
import VoucherModeHandler from '../../controller/voucherModeHandler/voucherModeHandler';

const Scanner = function ({ showLatestConfigModal }) {

  const hints = new Map();
  const formats = Configs.BARCODE_FORMAT_SUPPORTS;
  hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

  const history = useHistory();
  const { t } = useTranslation();
  const {
    scannerUser,
    token,
    barcode,
    setBarcode,
    setBarcodeScan,
    reason,
    setReason,
    scannerUserRole,
    setAppMode,
    setFromPath,
  } = useContext(AppContext);
  const [error, setError] = useState(null);
  const scanWidth = window.innerWidth;
  const scanHeight = window.innerHeight;
  const scannerWidth = window.innerWidth * 0.8;
  const scannerHeight = window.innerHeight;
  const scanStyle = {
    backgroundImage: `url(${scanImg})`,
    height: scannerWidth,
  };
  const scanAnimateStyle = {
    top: scannerWidth,
  };
  const [scannerStatus, setScannerStatus] = useState(reason ? false : true);
  const [isShowResult, setIsShowResult] = useState(false);
  const [lastScannedResult, setLastScannedResult] = useState();
  const [isErrorScan, setIsErrorScan] = useState(true);
  const [btnDisabledStatus, setBtnDisabledStatus] = useState();
  const [eventClass, setEventClass] = useState('event-gate');
  const [scanAreaClass, setScanAreaClass] = useState('scan-area-border');
  const [adType, setAdType] = useState(
    ScanInHandler.getIsScanInInfo() === 'true'
      ? constants.AdmissionTypeIn
      : constants.AdmissionTypeOut,
  );
  const [source, setSource] = useState();
  const audioRef = useRef();
  const videoRef = useRef();
  const [eventNameInfo] = useState(EventDataHandler.getEventNameInfo());
  const [gateInfo] = useState(GateDataHandler.getGateInfo());
  const [gateNameInfo] = useState(GateDataHandler.getGateNameInfo());
  const [appMode] = useState(
    AppModeHandler.appMode() === undefined
      ? constants.AppModeOnline
      : AppModeHandler.appMode(),
  );
  const [validatorMode] = useState(
      ValidatorModeHandler.validatorMode() === undefined
          ? constants.ValidatorModeOff
          : ValidatorModeHandler.validatorMode(),
  );
  const [voucherMode] = useState(VoucherModeHandler.voucherMode());
  const [reader] = useState(new BrowserMultiFormatReader(hints, Configs.SCAN.SCANNER_WAIT_TIME))

  // get date time
  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  // enable vibration support
  navigator.vibrate =
    navigator.vibrate ||
    navigator.webkitVibrate ||
    navigator.mozVibrate ||
    navigator.msVibrate;

  useEffect(() => {
    // Stop scanning and hide UI elements if showLatestConfigModal is true
    if (showLatestConfigModal) {
      reader.reset();
      setScannerStatus(false);
    } else {
      startScanner();
      setScannerStatus(true);
    }
  }, [showLatestConfigModal]);

  useEffect(() => {
    // default = in
    if (ScanInHandler.getIsScanInInfo() === undefined) {
      ScanInHandler.setIsScanInInfo(true);
    }
    // default = 1 / online
    if (
      AppModeHandler.appMode() === undefined ||
      AppModeHandler.appMode() === false
    ) {
      setAppMode(constants.AppModeOnline);
      AppModeHandler.setAppMode(constants.AppModeOnline);
    }
    if (eventNameInfo && gateNameInfo) {
      setScannerStatus(true);
    }
    if (scannerHeight < 720) {
      setEventClass('event-gate short');
      setScanAreaClass('scan-area-border short');
    }

    // if override
    if (reason && barcode) {
      setScannerStatus(false);

      const param = {
        barcode: barcode.barcode,
        admissionType: adType, // 1 = in, 2 = out
        gate: gateInfo,
        overrideReason: reason,
      };
      if (appMode === constants.AppModeOnline) {
        // valid barcode from server if connection is available
        fetchApi(
          Configs.SCAN.API_METHOD,
          Configs.SCAN.API_PATH,
          onSuccessCallbackOverride,
          param,
          onErrorCallbackOverride,
        );
      } else if (appMode === constants.AppModeOffline) {
        offlineValidate(param, barcode.barcode);
      } else if (appMode === constants.AppModeEmergency) {
        emergencyValidate(param, barcode.barcode);
      }
    } else {
      if (barcode) {
        setLastScannedResult(barcode.scanMessage);
        if (appMode === constants.AppModeOnline && barcode.scanResult !== constants.ScanSuccess) {
          setIsErrorScan(true);
        }
      }
      setScannerStatus(true);
    }
    const historyUnlisten = history.listen(() => {
      reader.reset();
          });
    return () => {
      historyUnlisten();
    }
  }, [reason]);

  useEffect(() => {
    // console.log('barcode', barcode, 'scannerStatus', scannerStatus);
  }, [barcode]);

  useEffect(() => {
    if (scannerStatus) {
      // setIsShowResult(false);
      setBtnDisabledStatus(false);
    } else {
      // setIsShowResult(true);
      setBtnDisabledStatus(true);
    }
  }, [scannerStatus]);

  useEffect(() => {
    if (ScanInHandler.getIsScanInInfo() === 'true') {
      setAdType(constants.AdmissionTypeIn);
    } else {
      setAdType(constants.AdmissionTypeOut);
    }
  }, [ScanInHandler.getIsScanInInfo()]);

  const manualEntryHandler = () => {
    history.push(Configs.MANUALENTRY.PATH);
  };

  const validatorEntryHandler = () => {
    history.push(Configs.VALIDATORENTRY.PATH);
  };

  const startScanner = () => {
    let detectTimer = null;
    reader.decodeFromVideoDevice(
      undefined,
      videoRef.current,
      (result, err) => {
        if (detectTimer == null && !reason) {
          if (result) {
            let trimResult = result.text;
            trimResult = trimResult.replace(/[\n\r]+/g, '');
            detectTimer = setTimeout(() => {
              detectTimer = null;
            }, Configs.SCAN.RESULT_DISPLAY_DURATION);
            zxingDetected(trimResult);
          }
          if (err && !(err instanceof NotFoundException)) {
            console.error('decode err', err);
            detectTimer = setTimeout(() => {
              clearTimeout(detectTimer);
              detectTimer = null;
            }, Configs.SCAN.RESULT_DISPLAY_DURATION);
          }
        }
      },
    );
  }

  const onSuccessCallbackOverride = (response) => {
    // handle success
    console.log('scan override succ', response);
    console.log('reason & barcode', reason, barcode);
    setBarcode(response);
    playSound(true);
    if (navigator.vibrate) {
      // vibration API supported
      navigator.vibrate(Configs.SCAN.SUCCESS_VIBRATE_DURATION);
    }
    setIsShowResult(true);
    setLastScannedResult(barcode.scanReason);
    console.log('called override');
    // chg status & hide result after X seconds
    setTimeout(() => {
      setScannerStatus(true);
      setIsShowResult(false);
      setReason(undefined);
      document.querySelector('.scan-override').classList.remove('active');
      // const eleOverride = document.querySelector('.scan-override');
      const elem = document.getElementById('scan-override-text');
      window.onload = function () {
        // remove after select reason & complete load
        if (elem.parentNode !== undefined && elem.parentNode !== undefined) {
          elem.parentNode.removeChild(elem);
        }
      };
    }, Configs.OVERRIDE.SUCCESS_DISPLAY_DURATION);
  };

  const onErrorCallbackOverride = (err, status, message) => {
    setScannerStatus(false);
    showErrorNotification({message: `${status}: ${message}` || err});
  };

  const onSuccessCallback = (response, status) => {
    // handle success - only for appMode = 1 / online
    console.log('scan succ', response, status, reason, barcode);
    setBarcode(response); // set barcode variable with response data
    setIsShowResult(true);
    if (response.scanResult !== constants.ScanSuccess) {
      setBarcodeScan(true);
      setIsErrorScan(true);
    }
    if (response.scanResult === 1) {
      setLastScannedResult(`${response.scanMessage}, ${response.scanReason}`);
    } else {
      setLastScannedResult(response.scanMessage);
    }
    toggleOverrideBtn(response);
    // chg status & hide result after X seconds
    if (voucherMode) {
      reader.reset();
    } else {
    setTimeout(() => {
      setScannerStatus(true);
      setIsShowResult(false);
    }, Configs.SCAN.RESULT_DISPLAY_DURATION);
    }
  };

  const onErrorCallback = (err, status, message) => {
    setScannerStatus(false);
    showErrorNotification({message: `${status}: ${message}` || err});
  };

  const onProceed = () => {
    if (voucherMode) {
      setScannerStatus(true);
      setIsShowResult(false);
      startScanner();
    }
  }

  const toggleOverrideBtn = (response) => {
    if (response.scanResult === constants.ScanSuccess) {
      // success
      //console.log('appMode = 1 / API only success');
      playSound(true);
      document.querySelector('.scan-override').classList.remove('active');
      if (navigator.vibrate) {
        // vibration API supported
        navigator.vibrate(Configs.SCAN.SUCCESS_VIBRATE_DURATION);
      }
    } else {
      // error
      //console.log('appMode = 1 / API only fail');
      playSound(false);
      if (!response.blockOverride && scannerUserRole !== constants.StaffScannerUserRole) {
      document.querySelector('.scan-override').classList.add('active');
      } else {
        document.querySelector('.scan-override').classList.remove('active');
      }
      if (navigator.vibrate) {
        // vibration API supported
        navigator.vibrate(Configs.SCAN.FAIL_VIBRATE_DURATION);
      }
    }
  };

  function SaveDataToLocalStorage(data) {
    let offlineData = [];
    // PofflineDatarse the serialized data back into an aray of objects
    offlineData = JSON.parse(OfflineBatchHandler.getOfflineBatchProcessData()) || [];
    // Push the new data (whether it be an object or anything else) onto the array
    offlineData.push(data);
    // Alert the array value
    // alert(offlineData); // Should be something like [Object array]
    // Re-serialize the array back into a string and store it in localStorage
    OfflineBatchHandler.setOfflineBatchProcessData(offlineData);
  }

  const zxingDetected = useCallback(
     (barcodeString) => {
      setScannerStatus(false);
      let param = {
        barcode: barcodeString,
        admissionType:
          ScanInHandler.getIsScanInInfo() === 'true'
            ? constants.AdmissionTypeIn
            : constants.AdmissionTypeOut, // 1 = in, 2 = out
        gate: gateInfo,
        // overrideReason: reason,
      };
      if (appMode === constants.AppModeOnline) {
        // valid barcode from server if connection is available
        // console.log('before fetch');
         fetchApi(
          Configs.SCAN.API_METHOD,
          Configs.SCAN.API_PATH,
          onSuccessCallback,
          param,
          onErrorCallback,
        );
      } else if (appMode === constants.AppModeOffline) {
        offlineValidate(param, barcodeString);
      } else  if (appMode === constants.AppModeEmergency) {
        emergencyValidate(param, barcodeString);
      }
    },
    [appMode, barcode, scannerStatus],
  );

  const offlineValidate = async (param, barcodeString) => {

        // offline - TODO
        // no connection, save barcode to local storage
    // const barcodeArr = barcodeString.match(/.{1,3}/g);
        // const eventCode = barcodeArr[0];
        // const sessionCode = barcodeArr[1];
        // const ticketID = barcodeArr[2];
        // console.log('split result --->', barcodeArr, eventCode, sessionCode, ticketID);
        let message;
        let override;
        let result = 1;
        let scanReason = null;
        param = {
          ...param,
          scannerProfile: PairScannerNameHandler.getScannerName(),
          scannerUser: ScannerUserHandler.getScannerUser(),
        };
    let validateResult = await validate(param);
    if (validateResult.error || validateResult.override) {
      if (validateResult.override && validateResult.override === true) {
            override = true;
        message = param.admissionType === constants.AdmissionTypeIn ? constants.AllowEntry : constants.AllowExit;
            result = 1;
          } else {
        message = t(validateResult.error?.type ? validateResult.error.type : validateResult.error);
        result = 0;
        if (scannerUserRole !== constants.StaffScannerUserRole) {
          document.querySelector('.scan-override').classList.add('active');
        }
          }
        } else {
      if (!validateResult.admissionRun) {
        validateResult.error = {priority: 1, type: 'validate_admission_barcode_invalid'};
        message = t(validateResult.error?.type ? validateResult.error.type : validateResult.error);
        result = 0;
        if (scannerUserRole !== constants.StaffScannerUserRole) {
          document.querySelector('.scan-override').classList.add('active');
        }
      } else {
          if (param.admissionType === constants.AdmissionTypeIn) {
            if (constants.Validation.admissionCount > 1) {
              scanReason = `${constants.Validation.ticketAttribute?.attributeName} (${constants.Validation.admissionCount})`;
          } else {
              scanReason = constants.Validation.ticketAttribute?.attributeName;
          }
            message = constants.AllowEntry;
          } else message = constants.AllowExit;
          result = 1;
        }
    }

        const offlineParam = {
          recordId: Date.now(),
          barcodeId: constants.Validation.barcodeId, // if have imported barcode, else empty
          barcode: barcodeString,
      	  admissionType: param.admissionType, // 1 = in, 2 = out
          gateId: gateInfo,
          scanResult: result, // always success
          scanReason: scanReason,
          scanMessage: message,
          overrideReason: override ? param.overrideReason : null,
          admissionRuleId: constants.Validation.admissionRuleId,
      scanDate: new Date().toISOString(),
          scannerProfile: PairScannerNameHandler.getScannerName(),
          scanBy: scannerUser,
          scanCheck: constants.Validation.scanCheck, // based on rule if scan check was required
          scanMode: appMode,
          synced: false,
          admissionCount: constants.Validation.admissionCount,
          appResponseColor: constants.Validation.appResponseColor,
          // valid: 'valid',
        };
        setBarcode(offlineParam);
        SaveDataToLocalStorage(offlineParam);
        setScannerStatus(false);
        setIsShowResult(true);
        if (offlineParam.scanResult === 1) {
          setLastScannedResult(
            `${offlineParam.scanMessage}, ${offlineParam.scanReason}`,
          );
        } else {
          setLastScannedResult(offlineParam.scanMessage);
        }
        // toggleOverrideBtn(offlineParam);
        if (offlineParam.scanResult === constants.ScanSuccess) {
          playSound(true);
          if (navigator.vibrate) {
            // vibration API supported
            navigator.vibrate(Configs.SCAN.SUCCESS_VIBRATE_DURATION);
          }

      setTimeout(() => {
        setScannerStatus(true);
        setIsShowResult(false);
        setReason(undefined);
        document.querySelector('.scan-override').classList.remove('active');
        // const eleOverride = document.querySelector('.scan-override');
        const elem = document.getElementById('scan-override-text');
        window.onload = function () {
          // remove after select reason & complete load
          if (elem.parentNode !== undefined && elem.parentNode !== undefined) {
            elem.parentNode.removeChild(elem);
          }
        };
      }, Configs.OVERRIDE.SUCCESS_DISPLAY_DURATION);
      
        } else {
          setBarcodeScan(true);
          playSound(false);
          if (navigator.vibrate) {
            // vibration API supported
            navigator.vibrate(Configs.SCAN.FAIL_VIBRATE_DURATION);
          }
        }
        // chg status & hide result after X seconds
        setTimeout(() => {
          setScannerStatus(true);
          setIsShowResult(false);
        }, Configs.SCAN.RESULT_DISPLAY_DURATION);
  };

  const emergencyValidate = async (param, barcodeString) => {
        param = {
          ...param,
          scannerProfile: PairScannerNameHandler.getScannerName(),
          scannerUser: ScannerUserHandler.getScannerUser(),
        };
        // no connection, save barcode to local storage
        const emergencyParam = {
          recordId: Date.now(),
          barcodeId: constants.Validation.barcodeId, // if have imported barcode, else empty
          barcode: barcodeString,
          admissionType: param.admissionType, // 1 = in, 2 = out
          gateId: gateInfo,
          scanResult: 1, // always succ
          scanReason: '', // reason for succ / fail
          scanMessage: param.admissionType === constants.AdmissionTypeIn ? constants.AllowEntry: constants.AllowExit,
          overrideReason: null,
          admissionRuleId: constants.Validation.admissionRuleId,
          scanDate: new Date().toISOString(),
          scannerProfile: PairScannerNameHandler.getScannerName(),
          scanBy: scannerUser,
          scanCheck: 0, // based on rule if scan check was required
          scanMode: appMode,
          synced: false,
          admissionCount: constants.Validation.admissionCount,
          // valid: 'valid',
        };
        setBarcode(emergencyParam);
        SaveDataToLocalStorage(emergencyParam);
        setScannerStatus(false);
        setIsShowResult(true);
        setLastScannedResult(
          `${emergencyParam.scanMessage}, ${emergencyParam.scanReason}`,
        );
        playSound(true);
        if (navigator.vibrate) {
          // vibration API supported
          navigator.vibrate(Configs.SCAN.SUCCESS_VIBRATE_DURATION);
        }
        // chg status & hide result after X seconds
        setTimeout(() => {
          setScannerStatus(true);
          setIsShowResult(false);
        }, Configs.SCAN.RESULT_DISPLAY_DURATION);
  };

  // play notify sound after detected barcode
  const playSound = (isSuccess) => {
    const scanStatus = isSuccess
      ? Configs.SCAN.SUCCESS_SOUND
      : Configs.SCAN.FAIL_SOUND;
    setSource(scanStatus);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
      audioRef.current.play();
    }
  };

  const viewAuditHandler = () => {
      setFromPath(Configs.SCAN.PATH);
      history.push(Configs.VIEW_AUDIT.PATH);
  };

  return (
    <div className='video-container'>
      {/* id interactive is default - add 'target' in inputStream for customize */}
      {!showLatestConfigModal && (
        <video muted ref={videoRef} width={scanWidth} height={scanHeight} />
      )}
      <div id='interactive' className='viewport'>
        {/* can't display camera > hit error */}
        {error !== null && <p className='camera-error'>{error}</p>}

        {/* display camera successfully */}
        <div className='camera-overlay'>
          <div className='scan-here-text'>
            <span>
                <div className={`scan-color ${scannerStatus ? '' : 'red'}`} />
                {scannerStatus
                  ? t('scan_readyScanText_1')
                  : t('scan_readyScanText_2')}
              </span>
            {voucherMode &&
            <>
              <br/>
              <span className='voucher-mode-text'>
                  {t('scan_voucherModeText')}
              </span>
            </>}
          </div>
          <div className={'scan-container'}>
            <div className={`${scanAreaClass}`} style={scanStyle}>
              {!showLatestConfigModal && (
                <div className='scanAnimation' style={scanAnimateStyle} />
              )}
            </div>
            <div className={'scan-info'}>
              <div className={`${eventClass}`}>
                {/* <div className='scan-event'>
                  {eventNameInfo != 'undefined' && eventNameInfo}
                </div> */}
                <div className='scan-gate'>
                  {gateNameInfo != 'undefined' && gateNameInfo}
                </div>
              </div>
              {isShowResult && barcode.scanReason !== undefined && (
                <ResultDisplay
                  cName={`result-display-container ${
                    barcode.scanResult === 1 ? 'valid' : 'invalid'
                  } ${reason ? 'reason' : ''} ${
                    barcode.scanCheck === constants.ScanCheckTrue 
                    ? 'scanCheck'
                    : ''
                  }
                  ${voucherMode ? 'big': ''}`}
                  style={
                    barcode.scanResult === 1 && barcode.appResponseColor && barcode.appResponseColor !== constants.defaultSuccessColorCode && !reason
                      ? { backgroundColor: barcode.appResponseColor }
                      : {}
                  }
                  onClick={onProceed}
                />
              )}
            </div>
          </div>
          <div className='scanner-bottom'>
            {lastScannedResult && isErrorScan && (
                <div className='last-scanned-result' onClick={isErrorScan ? viewAuditHandler :undefined}>
                  Last scanned result: {lastScannedResult}
                  {isErrorScan && <><br/>[ Tap to view audit ]</>}
                </div>
            )}
          <Button
            cName='enter-barcode-btn'
            disable={btnDisabledStatus}
              funct={manualEntryHandler}
            text={t('scan_btnText')}
          />
            {validatorMode == constants.ValidatorModeOn && (
            <Button
                cName='enter-barcode-btn'
                disable={btnDisabledStatus}
                funct={validatorEntryHandler}
                text={t('scan_btnValidator')}
            />
            )}
          </div>
        </div>
      </div>
      <audio controls ref={audioRef}>
        <track kind='captions' />
        <source src={source} type='audio/mpeg' />
      </audio>
    </div>
  );
};

export default withRouter(Scanner);
