import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import Configs from '../../Configs';
import { fetchApi } from '../../AjaxUtil';
import '../../styles/report.css';
import { Skeleton } from 'antd';
import { useErrorHanlder } from '../../context/errorContext';
const ReportDetails = function ({ history }) {
  const historyy = useHistory();
  const { t } = useTranslation();
  const [eventName, setEventName] = useState('');
  const [eventCode, setEventCode] = useState('');
  const [sessionDate, setSessionDate] = useState('');
  const [sessionTime, setSessionTime] = useState('');
  const [duration] = useState('All'); // always = all
  const [scanInCount, setScanInCount] = useState(0);
  const [isScanInCountPending, setIsScanInCountPending] = useState(true);
  const [scanOutCount, setScanOutCount] = useState(0);
  const [isScanOutCountPending, setIsScanOutCountPending] = useState(true);
  const [remainingCount, setRemainingCount] = useState(0);
  const [totalIssued, setTotalIssued] = useState(0);
  const [isRemainingCountPending, setIsRemainingCountPending] = useState(true);
  const [, setOfflineCount] = useState(0);
  const [percent, setPercent] = useState(9);
  const [statData, setStatData] = useState('');
  const [isOccupancySummaryPending, setIsOccupancySummaryPending] =
    useState(true);
  const COLORS = ['#CFD4DA', '#003E99']; // scanned , remaining
  const { showErrorNotification } = useErrorHanlder()

  useEffect(() => {
    if (history.location.state !== undefined) {
      setEventName(history.location.state.eventName);
      setEventCode(history.location.state.eventCode);
      setSessionDate(history.location.state.sessionDate);
      setSessionTime(history.location.state.sessionTime);

      fetchSyncBarcodeApi(
        history.location.state.eventId,
        history.location.state.sessionId
      );
    }
  }, []);

  const onSuccessCallback = (response) => {
    const {
      patronEntry,
      patronExit,
      remaining,
      offlineCount,
      total
      } = response[0];
    setScanInCount(patronEntry);
    setIsScanInCountPending(false);
    setScanOutCount(patronExit);
    setIsScanOutCountPending(false);
    setRemainingCount(remaining);
    setIsRemainingCountPending(false);
    setOfflineCount(offlineCount);
    const scanned = total - remaining;
    const percentage =
      total === 0
        ? 0
        : Math.round(((scanned) / total) * 100 * 10) /
          10; // round to 1 decimal
    setPercent(percentage);
    const issued = patronEntry + remaining
    setTotalIssued(issued)
    const data = [
      {
        name: t('report_chartLabel_1'), // 'Total Capacity', // display remaining in chart
        value: scanned === 0 && remaining === 0 ? 1 : remaining,
      },
      {
        name: t('report_chartLabel_2'), // 'Current Occupancy',
        value: scanned,
      },
    ];
    setStatData(data);
    setIsOccupancySummaryPending(false);
  };

  const onErrorCallback = (err, status, message) => {
    showErrorNotification({message: `${status}: ${message}` || err});
  };

  async function fetchSyncBarcodeApi(event, session) {
    const param = {
      eventId: event,
      sessionId: session,
      duration,
    };

    await fetchApi(
      Configs.REPORT_DETAILS.API_METHOD,
      Configs.REPORT_DETAILS.API_PATH,
      onSuccessCallback,
      param,
      onErrorCallback
    );
  }

  const backHandler = () => {
    historyy.goBack();
  };

  return (
    <div className='report-form'>
      <div className='report-container'>
        <div className='report-top'>
          <div
            className='back-btn'
            onClick={backHandler}
            onKeyPress={backHandler}
            role='button'
            tabIndex='0'
          >
            {t('report_backText')}
          </div>
          <div className='report-details'>{t('report_headerText')}</div>
        </div>
        <div className='report-container-box'>
          <div className='report-data-blocks'>
            <div className='total-ticket-block'>
              <p className='report-ticket-title'>
                {t('report_chartTitle')}
              </p>
              <p className='report-ticket-number'>
                {eventName} ({eventCode}), {sessionDate}, {sessionTime}
              </p>
            </div>
          </div>
          <div className='report-pie-chart-block'>
            {isOccupancySummaryPending ? (
              <Skeleton.Avatar
                className='report-pie-chart-block-pending'
                active={true}
                shape='square'
              />
            ) : (
              <>
                <div className='pie-chart-label'>
                  {percent}% <br />{' '}
                  <span className='label-text'>
                    {t('report_chartInnerLabelText')}
                  </span>
                </div>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      dataKey='value'
                      isAnimationActive
                      animationDuration={800}
                      data={statData}
                      cx='50%'
                      cy='50%'
                      startAngle={90}
                      endAngle={450}
                      innerRadius={85}
                      outerRadius={110}
                      paddingAngle={0}
                      stroke=''
                    >
                      {statData.map((entry, index) => (
                        <Cell
                          key={`cell-${entry}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend
                      align='center'
                      iconSize='12'
                      iconType='circle'
                    />
                  </PieChart>
                </ResponsiveContainer>
              </>
            )}
          </div>
        </div>
        <div className='report-details-container'>
          <div className='report-details-title'>
            {t('report_scanInTitle')}
          </div>
          <div className='report-details-text'>
            {isScanInCountPending ? (
              <Skeleton.Input active={true} size={'small'} />
            ) : (
              scanInCount.toLocaleString('en-US')
            )}
          </div>
          <div className='report-details-title'>
            {t('report_scanOutTitle')}
          </div>
          <div className='report-details-text'>
            {isScanOutCountPending ? (
              <Skeleton.Input active={true} size={'small'} />
            ) : (
              scanOutCount.toLocaleString('en-US')
            )}
          </div>
          <div className='report-details-title'>
            {t('report_scanRemainingTitle')}
          </div>
          <div className='report-details-text'>
            {isRemainingCountPending ? (
              <Skeleton.Input active={true} size={'small'} />
            ) : (
              remainingCount.toLocaleString('en-US')
            )}
          </div>
          <div className='report-details-title'>
            {t('report_totalIssued')}
          </div>
          <div className='report-details-text'>
            {isRemainingCountPending ? (
              <Skeleton.Input active={true} size={'small'} />
            ) : (
              totalIssued.toLocaleString('en-US')
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// props.history.location.state.from
ReportDetails.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        eventId: PropTypes.string,
        sessionId: PropTypes.string,
        eventName: PropTypes.string.isRequired,
        eventCode: PropTypes.string.isRequired,
        sessionCode: PropTypes.string.isRequired,
        sessionDate: PropTypes.string.isRequired,
        sessionTime: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default ReportDetails;
