import React from 'react';
import PropTypes from 'prop-types';

const ToastMessage = function (props) {
  return <div className={`toast-msg ${props.cName}`}>{props.msg}</div>;
};
export default ToastMessage;

ToastMessage.propTypes = {
  cName: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
};
