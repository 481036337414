import { Engine } from "json-rules-engine";
import * as constants from "../../constants/constants";

export async function validateAdmissionCount(
    allowedCount,
    admissionRule,
    admissionType,
    error,
) {
    
    let admissionCountError;
    const engine = new Engine();
    engine.addOperator("isInfinite", (factValue) => {
        if (factValue === -1) return true;
        return false;
    });

    const admissionCount = {
        conditions: {
            any: [
                {
                    fact: "allowedCount",
                    operator: "isInfinite",
                    value: true,
                },
                {
                    all: [
                        {
                            fact: "allowedCount",
                            operator: "greaterThan",
                            value: allowedCount,
                        },
                        {
                            fact: "allowedCount",
                            operator: "greaterThan",
                            value: 0,
                        },
                    ],
                },
            ],
        },
        event: {
            type: "numberOfAdmission",
        },
        priority: 10,
        onSuccess: function () {
            engine.stop();
            admissionCountError = null;
        },
        onFailure: function () {
            engine.stop();
            if (admissionType === constants.AdmissionTypeIn) {
                if (error === null || error === undefined || error.priority <= 5) {
                    admissionCountError = { priority: 5, type: "validate_admission_count_invalid" };
                } else {
                    admissionCountError = error;
                }
            } else {
                admissionCountError = null;
            }
        },
    };

    engine.addRule(admissionCount);
    await engine.run(admissionRule);
    return admissionCountError;
}
