import React, { useContext } from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/appContext';
import Configs from '../../Configs';
import '../../styles/overrideResult.css';

const OverrideResult = function () {
  const history = useHistory();
  const { t } = useTranslation();
  const reasons = Configs.OVERRIDE.REASON;
  const { reason, setReason, setFromPath } = useContext(AppContext);

  const reasonChangeHandler = (item) => {
    setReason(item);

    setFromPath(Configs.OVERRIDE.PATH);
    if (history.location.state && history.location.state.from === Configs.VALIDATORENTRY.PATH) {
      setTimeout(() => history.push(Configs.VALIDATORENTRY.PATH), Configs.OVERRIDE.TIMEOUT_DURATION);
    } else {
    setTimeout(() => history.goBack(), Configs.OVERRIDE.TIMEOUT_DURATION);
    }
  };

  // go to scanner
  const backHandler = () => {
    if (history.location.state && history.location.state.from === Configs.VALIDATORENTRY.PATH) {
      setFromPath(Configs.VALIDATORENTRY.PATH);
    }
    history.goBack();
  };

  return (
    <div className='override-form'>
      <div className='override-container'>
        <div className='override-top'>
          <div
            className='back-btn'
            onClick={backHandler}
            onKeyPress={backHandler}
            role='button'
            tabIndex='0'
          >
            {t('override_backText')}
          </div>
          <div className='override-details'>{t('override_headerText')}</div>
        </div>
        <div className='override-form-box'>
          {reasons.map((item) => (
            <div
              key={item}
              className={`override-form-title ${
                t(item) === reason && 'active'
              }`}
              onClick={() => reasonChangeHandler(t(item))}
              onKeyPress={() => reasonChangeHandler(t(item))}
              role='button'
              tabIndex='0'
            >
              {t(item)}
              {t(item) === reason ? <FontAwesomeIcon icon={faCheck} /> : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OverrideResult;
