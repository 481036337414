import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../context/appContext';
import { Button as MaterialButton } from '@material-ui/core';
import ResultDisplay from '../resultDisplay/ResultDisplay';
import Button from '../button/Button';
import GateDataHandler from '../../controller/eventHandler/GateHandler';
import OfflineBatchHandler from '../../controller/syncHandler/OfflineBatchHandler';
import ScanInHandler from '../../controller/scanHandler/ScanInHandler';
import AppModeHandler from '../../controller/appModeHandler/AppModeHandler';
import { fetchApi } from '../../AjaxUtil';
import Configs from '../../Configs';
import * as constants from '../../constants/constants';
import '../../styles/manualEntry.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faTimes,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import TransactionResultDisplay from '../resultDisplay/transactionResultDisplay';
import PairScannerNameHandler from '../../controller/authHandler/PairScannerNameHandler';
import ScannerUserHandler from '../../controller/authHandler/ScannerUserHandler';
import { validate } from '../../offlineValidation/validate';
import VoucherModeHandler from '../../controller/voucherModeHandler/voucherModeHandler';
import Popup from "../popup/Popup";
import { useErrorHanlder } from '../../context/errorContext';

const ManualEntry = function () {
  const {
    barcode,
    setBarcode,
    reason,
    setReason,
    token,
    scannerUser,
    setFromPath,
    scannerUserRole,
  } = useContext(AppContext);
  const history = useHistory();
  const { t } = useTranslation();
  const { register, handleSubmit, reset } = useForm();
  const [ticketCode, setTicketCode] = useState('');
  const [adType, setAdType] = useState(
    ScanInHandler.getIsScanInInfo() === 'true'
      ? constants.AdmissionTypeIn
      : constants.AdmissionTypeOut,
  );
  const [isShowResult, setIsShowResult] = useState(false);
  const [lastScannedResult, setLastScannedResult] = useState();
  const [isDisableBtn, setIsDisableBtn] = useState(true);
  const [isValid, setIsValid] = useState();
  const [btnDisabledStatus, setBtnDisabledStatus] = useState();
  const [admitBtnDisabledStatus, setAdmitBtnDisabledStatus] = useState(true);
  const [source, setSource] = useState();
  const audioRef = useRef();
  // get date time
  const [gateInfo] = useState(GateDataHandler.getGateInfo());
  const [appMode] = useState(AppModeHandler.appMode());
  const [type, setType] = useState(constants.ManualEntryTicket);
  const [showTickets, setShowTickets] = useState(false);
  const [tickets, setTickets] = useState();
  const [totalSubmitTicket, setTotalSubmitTicket] = useState(null);
  const [totalUnused, setTotalUnused] = useState(0);
  const [totalInvalid, setTotalInvalid] = useState(0);
  const [admitBtn, setAdmitBtn] = useState(false);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [isTransactionResult, setIsTransactionResult] = useState(false);
  const [transactionResult, setTransactionResult] = useState('');
  const [isTransactionResultSuccess, setIsTransactionResultSuccess] = useState(0);
  const [isTransactionLoading, setIsTransactionLoading] = useState(false);
  const [isTicketOption, setIsTicketOption] = useState(false);
  const [isTransactionOption, setIsTransactionOption] = useState(false);
  const [showStaffAccessInfo, setShowStaffAccessInfo] = useState(false);
  const [voucherMode] = useState(VoucherModeHandler.voucherMode());
  const timerRef = useRef(null);
  const { showErrorNotification } = useErrorHanlder()
  const [showPopup,setShowPopup] = useState(false)


  useEffect(() => {
    // if override
    if (reason && barcode) {
      setIsShowResult(true);
      // start of API - for online only
      if (appMode === constants.AppModeOnline) {
        // valid barcode from server if connection is available
        let param = null;

        // Handle barcode or ticketId
        if (barcode.barcode) {
          param = {
          barcode: barcode.barcode,
          admissionType: adType, // 1 = in, 2 = out
          gate: gateInfo,
          overrideReason: reason,
          validateType: "MANUAL"
        };
        } else {
          param = {
            ticketId: barcode.ticketId,
            admissionType: adType, // 1 = in, 2 = out
            gate: gateInfo,
            overrideReason: reason,
          validateType: "MANUAL"
          };
        }

        fetchApi(
          Configs.SCAN.API_METHOD,
          Configs.SCAN.API_PATH,
          onSuccessCallbackOverride,
          param,
          onErrorCallbackOverride,
        );
      } else {
        // In offline mode, need to redirect the user back to main page
        history.push(Configs.SCAN.PATH);
      }
    } else {
      setIsShowResult(false);
    }

    // Disable when no offline
    if (appMode === constants.AppModeOffline || appMode === constants.AppModeEmergency) {
      setIsTicketOption(true);
      setIsTransactionOption(true);
      barcodeDefault(true);
    } else {
      barcodeDefault(false);
    }
  }, []);

  useEffect(() => {
    if (isDisableBtn || !ticketCode) {
      setBtnDisabledStatus(true);
    } else {
      setBtnDisabledStatus(false);
    }
  }, [isDisableBtn]);

  useEffect(() => {
    if (ScanInHandler.getIsScanInInfo() === 'true') {
      setAdType(constants.AdmissionTypeIn);
    } else {
      setAdType(constants.AdmissionTypeOut);
    }
  }, [ScanInHandler.getIsScanInInfo()]);

  useEffect(() => {
    if (parseInt(totalSubmitTicket) >= Configs.MANUALENTRYTRANSACTION.ALERT_MSG_SHOW_AT) {
      setAlertMessage(true);
    } else setAlertMessage(false);
  }, [totalSubmitTicket]);

   useEffect(() => {
     if (showStaffAccessInfo) {
       if (timerRef.current) clearTimeout(timerRef.current);

       timerRef.current = setTimeout(() => {
         setShowStaffAccessInfo(false);
       }, Configs.GATE_REPORT.STAFF_DENY_ENTRY_DURATION);
       return () => clearTimeout(timerRef.current);
     }
   }, [showStaffAccessInfo]);

  // useEffect(() => {
  //   console.log('barcode', barcode, 'isValid', isValid)
  // }, [barcode, isValid]);

  // go to scanner
  const backHandler = () => {
    history.push(Configs.SCAN.PATH);
  };

  const handleTicketCodeChange = (event) => {
    // Reset fields to search again
    setTotalSubmitTicket('');
    setAdmitBtn(false);
    setAdmitBtnDisabledStatus(true);
    setShowTickets(false);
    setIsDisableBtn(true);

    setTicketCode(event.target.value.trim());
    if (event.target.value.trim()) {
      setIsDisableBtn(false);
    } else {
      setIsDisableBtn(true);
    }
  };

  const handleTotalTicketSubmitChange = (event) => {
    const re = /^[1-9]\d{0,11}$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      setTotalSubmitTicket(event.target.value.trim());
    }
    if (parseInt(event.target.value.trim()) === 0 || !event.target.value.trim()
    ) {
      setAdmitBtnDisabledStatus(true);
    } else {
      setAdmitBtnDisabledStatus(false);
    }
  };

  const overrideHandler = () => {
    if (scannerUserRole === constants.StaffScannerUserRole && showPopup) {
      setShowStaffAccessInfo(true)
      return
    }
      if (
        document.querySelector('.manual-override').classList.contains('active')
      ) {
        history.push(Configs.OVERRIDE.PATH);
      }
  };

  const onSuccessCallbackOverride = (response) => {
    // handle success
    // console.log('manualEntry override succ', response);
    setBarcode(response);
    setIsValid(true);
    if (navigator.vibrate) {
      // vibration API supported
      navigator.vibrate(Configs.SCAN.SUCCESS_VIBRATE_DURATION);
    }
    // chg status & hide result after X seconds
    setTimeout(() => {
      setReason();
      setIsShowResult(false);
      setIsDisableBtn(false);
      document.querySelector('.manual-override').classList.remove('active');
      history.push(Configs.SCAN.PATH);
    }, Configs.OVERRIDE.SUCCESS_DISPLAY_DURATION);
  };

  const onErrorCallbackOverride = (err, status, message) => {
    showErrorNotification({message: `${status}: ${message}` || err});
  };

  const onSuccessCallback = (response) => {
    // handle success
    // console.log('manualEntry succ', response, status);
    setIsShowResult(true);
    setBarcode(response); // set barcode variable with response data
    if (response.scanResult === constants.ScanSuccess) {
      // success
      setIsValid(true);
      playSound(true);
      if (navigator.vibrate) {
        // vibration API supported
        navigator.vibrate(Configs.SCAN.SUCCESS_VIBRATE_DURATION);
      }
      document.querySelector('.manual-override').classList.remove('active');
    } else {
      // error
      setIsValid(false);
      setShowPopup(true);
      setLastScannedResult(response.scanMessage);
      playSound(false);
      if (navigator.vibrate) {
        // vibration API supported
        navigator.vibrate(Configs.SCAN.FAIL_VIBRATE_DURATION);
      }
      if (!response.blockOverride && scannerUserRole !== constants.StaffScannerUserRole) {
      setShowPopup(true);
      document.querySelector('.manual-override').classList.add('active');
      } else {
        document.querySelector('.manual-override').classList.remove('active');
      }
    }
    // chg status & hide result after X seconds
     if (!voucherMode) {
    setTimeout(() => {
      setIsShowResult(false);
      setIsDisableBtn(false);
      if (response.scanResult === constants.ScanSuccess) {
      history.push(Configs.SCAN.PATH);
      }
    }, Configs.MANUALENTRY.RESULT_DISPLAY_DURATION);
     }
  };

  const onProceed = () => {
    if (voucherMode) {
      setIsShowResult(false);
        setIsDisableBtn(false); 
    }
  }

  const onSuccessFetchTicketsCallback = (response) => {
    let invalid = 0;
    let unused = 0;
    setTickets(response);
    response.forEach((barcode) => {
      if (barcode.statusMessage == 'Blacklisted' || barcode.statusMessage == 'Used') {
        setTotalInvalid((invalid += 1));
      } else {
        setTotalUnused((unused += 1));
      }
    });
    setIsTransactionLoading(false);
    if (response.length>0) {
    setAdmitBtn(true);
      if (unused > 0) {
        setAdmitBtnDisabledStatus(false);
      }
      setTotalSubmitTicket(unused);
    }
  };

const onErrorCallback = (err, status, message) => {
    showErrorNotification({message: `${status}: ${message}` || err});
    setShowPopup(true);
    // handle error
    // console.log('manualEntry err', response, status);
  };

  const typeChange = (type) => {
    if (type !== constants.ManualEntryTransaction) {
      setShowTickets(false);
    }
    const typeBtns = document.querySelectorAll('.manual-type-button');
    typeBtns.forEach((btn) => {
      if (btn.classList.contains(type)) {
        btn.classList.add('active');
      } else {
        btn.classList.remove('active');
      }
    });
    setType(type);
    setAdmitBtn(false);
    setTotalSubmitTicket('');
    setShowConfirmBox(false);
    setIsTransactionResult(false);
  };

  const barcodeDefault = (makeDefault) => {
    const typeBtns = document.querySelectorAll('.manual-type-button');
    typeBtns.forEach((btn) => {
      if (makeDefault && btn.classList.contains(constants.ManualEntryBarcode)) {
        btn.classList.add('active');
        setType(constants.ManualEntryBarcode);
      } else if (!makeDefault && btn.classList.contains(constants.ManualEntryTicket)) {
        btn.classList.add('active');
        setType(constants.ManualEntryTicket);
      } else {
        btn.classList.remove('active');
      }
    });
  };

  const getTransactionTickets = async (transactionId) => {
    setTickets([]);
    setTotalUnused(0);
    setTotalInvalid(0);
    setShowTickets(true);
    setIsTransactionLoading(true);
    setLastScannedResult('');
    Configs.MANUALENTRYTRANSACTION.API_PATH = `fetchTickets/${transactionId}/`;
    await fetchApi(
      Configs.MANUALENTRYTRANSACTION.API_METHOD,
      Configs.MANUALENTRYTRANSACTION.API_PATH,
      onSuccessFetchTicketsCallback,
      null,
      onErrorCallback,
    );
  };

  const onSubmit = useCallback(
    async (data) => {
      setIsDisableBtn(true);
      if (type === constants.ManualEntryTransaction) {
        await getTransactionTickets(data.ticketcode);
      } else if (type === constants.ManualEntryTicket) {
        let param = {
          ticketId: data.ticketcode,
          admissionType: adType, // 1 = in, 2 = out
          gate: gateInfo,
          validateType: "MANUAL"
          // overrideReason: "overrideReason",
        };
        if (appMode === constants.AppModeOnline) {
          // valid barcode from server if connection is available
          await fetchApi(
            Configs.SCAN.API_METHOD,
            Configs.SCAN.API_PATH,
            onSuccessCallback,
            param,
            onErrorCallback,
          );
        } else if (appMode === constants.AppModeOffline) {
          offlineValidate(param, data);
        } else {
          emergencyMode(param, data);
        }
      } else {
        let param = {
          barcode: data.ticketcode,
          admissionType: adType, // 1 = in, 2 = out
          gate: gateInfo,
          validateType: "MANUAL"
          // overrideReason: "overrideReason",
        };
        if (appMode === constants.AppModeOnline) {
          // valid barcode from server if connection is available
          await fetchApi(
            Configs.SCAN.API_METHOD,
            Configs.SCAN.API_PATH,
            onSuccessCallback,
            param,
            onErrorCallback,
          );
        } else if (appMode === constants.AppModeOffline) {
          offlineValidate(param, data);
        } else {
          emergencyMode(param, data);
        }
      }
    },

    [appMode, barcode, type, ticketCode],
  );

  const emergencyMode = async (param, data) => {
    // emergency - TODO
    // no connection, save barcode to local storage
    // const barcodeArr = data.ticketcode.match(/.{1,3}/g);
    // const eventCode = barcodeArr[0];
    // const sessionCode = barcodeArr[1];
    // const ticketID = barcodeArr[2];
    // console.log('split result --->', barcodeArr, eventCode, sessionCode, ticketID);
    const emergencyParam = {
      recordId: Date.now(),
      barcodeId: constants.Validation.barcodeId, // if have imported barcode, else empty
      barcode: data.ticketcode,
      admissionType: adType, // 1 = in, 2 = out
      gateId: gateInfo,
      scanResult: 1, // always success
      scanReason: '', // reason for succ / fail - should not show anything
      scanMessage: param.admissionType === constants.AdmissionTypeIn ? constants.AllowEntry: constants.AllowExit,
      overrideReason: null,
      admissionRuleId: constants.Validation.admissionRuleId,
      scanDate: new Date().toISOString(),
      scannerProfile: PairScannerNameHandler.getScannerName(),
      scanBy: scannerUser,
      scanCheck: 0, // based on rule if scan check was required
      scanMode: appMode,
      synced: false,
      admissionCount: constants.Validation.admissionCount,
      validateType: "MANUAL"
      // valid: 'valid',
    };
    setBarcode(emergencyParam);
    SaveDataToLocalStorage(emergencyParam);
    setIsShowResult(true);
    playSound(true);
    setIsValid(true);
    // chg status & hide result after X seconds
    if (!voucherMode) {
    setTimeout(() => {
      setIsShowResult(false);
      setIsDisableBtn(false);
      // if (barcode.scanResult === 1) {
      history.push(Configs.SCAN.PATH);
      // }
    }, Configs.MANUALENTRY.RESULT_DISPLAY_DURATION);
    }
  };

  const offlineValidate = async (param, data) => {
    // offline - TODO
    // no connection, save barcode to local storage
    // const barcodeArr = data.ticketcode.match(/.{1,3}/g);
    // const eventCode = barcodeArr[0];
    // const sessionCode = barcodeArr[1];
    // const ticketID = barcodeArr[2];
    // console.log('split result --->', barcodeArr, eventCode, sessionCode, ticketID);
    let message;
    let override;
    let result = 1;
    let scanReason = null;
    param = {
      ...param,
      scannerProfile: PairScannerNameHandler.getScannerName(),
      scannerUser: ScannerUserHandler.getScannerUser(),
    };
    let validateResult = await validate(param);
    if (validateResult.error || validateResult.override) {
      setShowPopup(true);
      if (validateResult.override && validateResult.override === true) {
        override = true;
        message = param.admissionType === constants.AdmissionTypeIn ? constants.AllowEntry : constants.AllowExit;
        result = 1;
      } else {
        message = t(validateResult.error?.type ? validateResult.error.type : validateResult.error);
        result = 0;
        setLastScannedResult(message);
        if (scannerUserRole !== constants.StaffScannerUserRole) {
          document.querySelector('.manual-override').classList.add('active');
        }
      }
    } else {
      if (!validateResult.admissionRun) {
        setShowPopup(true);
        validateResult.error = {priority: 1, type: 'validate_admission_barcode_invalid'};
        message = t(validateResult.error?.type ? validateResult.error.type : validateResult.error);
        result = 0;
        setLastScannedResult(message);
        if (scannerUserRole !== constants.StaffScannerUserRole) {
          document.querySelector('.manual-override').classList.add('active');
        }
      } else {
      if (param.admissionType === constants.AdmissionTypeIn) {
          //console.log(constants.Validation.admissionCount);
        if (constants.Validation.admissionCount > 1) {
          scanReason = `${constants.Validation.ticketAttribute?.attributeName} (${constants.Validation.admissionCount})`;
          } else {
            scanReason = constants.Validation.ticketAttribute?.attributeName;
          }
        message = constants.AllowEntry;
      } else message = constants.AllowExit;
      result = 1;
    }
    }

    const offlineParam = {
      recordId: Date.now(),
      barcodeId: constants.Validation.barcodeId, // if have imported barcode, else empty
      barcode: constants.Validation.barcode,
      admissionType: adType, // 1 = in, 2 = out
      gateId: gateInfo,
      scanResult: result, // always success
      scanReason: scanReason,
      scanMessage: message,
      overrideReason: override ? param.overrideReason : null,
      admissionRuleId: constants.Validation.admissionRuleId,
      scanDate: new Date().toISOString(),
      scannerProfile: PairScannerNameHandler.getScannerName(),
      scanBy: scannerUser,
      scanCheck: constants.Validation.scanCheck, // based on rule if scan check was required
      scanMode: appMode,
      synced: false,
      admissionCount: constants.Validation.admissionCount,
      validateType: "MANUAL",
      appResponseColor: constants.Validation.appResponseColor,
      // valid: 'valid',
    };
    setBarcode(offlineParam);
    SaveDataToLocalStorage(offlineParam);
    setIsShowResult(true);
    if (offlineParam.scanResult === constants.ScanSuccess) {
      setIsValid(true);
      playSound(true);
      if (navigator.vibrate) {
        // vibration API supported
        navigator.vibrate(Configs.SCAN.SUCCESS_VIBRATE_DURATION);
      }
      if (!voucherMode) {
      setTimeout(() => {
        setReason();
        setIsShowResult(false);
        setIsDisableBtn(false);
        document.querySelector('.manual-override').classList.remove('active');
        history.push(Configs.SCAN.PATH);
      }, Configs.OVERRIDE.SUCCESS_DISPLAY_DURATION);
      } else {
          document.querySelector('.manual-override').classList.remove('active');
      }
      
    } else {
      setIsValid(false);
      playSound(false);
      setShowPopup(true);
      if (navigator.vibrate) {
        // vibration API supported
        navigator.vibrate(Configs.SCAN.FAIL_VIBRATE_DURATION);
      }
    }
    // chg status & hide result after X seconds
    if (!voucherMode) {
    setTimeout(() => {
      setIsShowResult(false);
      setIsDisableBtn(false);
      // if (barcode.scanResult === 1) {
      if (offlineParam.scanResult === constants.ScanSuccess) {
      history.push(Configs.SCAN.PATH);
      }
      // }
    }, Configs.MANUALENTRY.RESULT_DISPLAY_DURATION);
    }
  };

  const clearManualInput = () => {
    setTicketCode('');
    setTotalSubmitTicket('');
    setAdmitBtn(false);
    setAdmitBtnDisabledStatus(true);
    setShowTickets(false);
    setIsDisableBtn(true);
  };

    const closeModal = () => {
      if (timerRef.current) clearTimeout(timerRef.current);
      setShowStaffAccessInfo(false);
    };

  const onTotalAdmit = async () => {
    setAdmitBtnDisabledStatus(true);
    setShowConfirmBox(true);
  };

  const onConfirmCancel = async () => {
    setAdmitBtnDisabledStatus(false);
    setShowConfirmBox(false);
  };

  const onConfirmSubmit = async () => {
    setShowConfirmBox(false);
    const unusedTickets = tickets.filter(
      (ticket) => ticket.status === 1 && ticket.statusMessage === constants.ManualTicketUnused,
    );
    if (unusedTickets.length < parseInt(totalSubmitTicket)) {
      console.log('Not allowed to submit');
    } else {
      if (appMode === constants.AppModeOnline) {
        const param = {
          transactionId: ticketCode,
          limitCount: parseInt(totalSubmitTicket),
          admissionType: adType, // 1 = in, 2 = out
          gate: gateInfo,
          validateType: "MANUAL"
          // overrideReason: "overrideReason",
        };
        // valid barcode from server if connection is available
        await fetchApi(
          Configs.SCAN.API_METHOD,
          Configs.SCAN.API_PATH,
          onSuccessTransactionIdCallback,
          param,
          onErrorCallback,
        );
      }
    }
    setTimeout(() => {
      setIsTransactionResult(false);
      setAdmitBtnDisabledStatus(false);
    }, Configs.MANUALENTRY.RESULT_DISPLAY_DURATION);
  };

  const onSuccessTransactionIdCallback = async (response) => {
    setIsTransactionResultSuccess(`${response.scanResult}`);
    setTransactionResult(`${response.scanMessage}`);
    setIsTransactionResult(true);
    if (response.scanResult == 1) {
      // success
      setIsValid(true);
      playSound(true);
      if (navigator.vibrate) {
        // vibration API supported
        navigator.vibrate(Configs.SCAN.SUCCESS_VIBRATE_DURATION);
      }
    } else {
      // error
      setIsValid(false);
      playSound(false);
      setShowPopup(true);
      if (navigator.vibrate) {
        // vibration API supported
        navigator.vibrate(Configs.SCAN.FAIL_VIBRATE_DURATION);
      }
    }
    setTimeout(() => {
      setIsTransactionResult(false);
      setAdmitBtnDisabledStatus(false);
      if (response.scanResult == 1) {
      history.push(Configs.SCAN.PATH);
      }
    }, Configs.MANUALENTRY.RESULT_DISPLAY_DURATION);
    await getTransactionTickets(ticketCode);
  };

  function SaveDataToLocalStorage(data) {
    let offlineData = [];
    // PofflineDatarse the serialized data back into an aray of objects
    offlineData = JSON.parse(OfflineBatchHandler.getOfflineBatchProcessData()) || [];
    // Push the new data (whether it be an object or anything else) onto the array
    offlineData.push(data);
    // Alert the array value
    // alert(offlineData); // Should be something like [Object array]
    // Re-serialize the array back into a string and store it in localStorage
    OfflineBatchHandler.setOfflineBatchProcessData(offlineData);
  }

  // play notify sound after detected barcode
  const playSound = (isSuccess) => {
    const scanStatus = isSuccess
      ? Configs.SCAN.SUCCESS_SOUND
      : Configs.SCAN.FAIL_SOUND;
    setSource(scanStatus);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
      audioRef.current.play();
    }
  };

  const viewAuditHandler = () => {
    setFromPath(Configs.MANUALENTRY.PATH);
    history.push(Configs.VIEW_AUDIT.PATH);
  };

  return (
    <form className='manual-form'>
      {showStaffAccessInfo
        ? <Popup
          icon={faExclamationCircle}
          onClose={closeModal}
          title={t('setting_gateStaffErrorTitle')}
          />  
      : null}
      <div className='manual-container'>
        <div className='manual-top'>
          <div
            className='back-btn'
            onClick={backHandler}
            onKeyPress={backHandler}
            role='button'
            tabIndex='0'
          >
            {t('manualEntry_backText')}
          </div>
          <div className='manual-details'>{t('manualEntry_headerText')}</div>
          <div
            className='manual-override'
            onClick={overrideHandler}
            onKeyPress={overrideHandler}
            role='button'
            tabIndex='0'
          >
            {t('manualEntry_headerOverride')}
          </div>
        </div>
        <div className='manual-type-container'>
          <div className='manual-type-selection'>
            <MaterialButton
              disableElevation
              variant='contained'
              className={`manual-type-button ${constants.ManualEntryTicket}`}
              onClick={() => typeChange(constants.ManualEntryTicket)}
              disabled={isTicketOption}
            >
              {t('manualEntry_ticket_id')}
            </MaterialButton>
            <MaterialButton
              disableElevation
              variant='contained'
              className={`manual-type-button ${constants.ManualEntryTransaction}`}
              onClick={() => typeChange(constants.ManualEntryTransaction)}
              disabled={isTransactionOption}
            >
              {t('manualEntry_transaction_id')}
            </MaterialButton>
            <MaterialButton
              disableElevation
              className={`manual-type-button ${constants.ManualEntryBarcode}`}
              variant='contained'
              onClick={() => typeChange(constants.ManualEntryBarcode)}
            >
              {t('manualEntry_barcode')}
            </MaterialButton>
          </div>
        </div>
        <div className='manual-form-box'>
          <div className='manual-ticket-group'>
            {ticketCode.length > 0 && (
              <FontAwesomeIcon
                icon={faTimes}
                className='manual-input-clear'
                onClick={clearManualInput}
              />
            )}
            <input
              {...register('ticketcode', { required: true })}
              name='ticketcode'
              id='ticketcode'
              className='form-ticketcode'
              placeholder={t('manualEntry_placeholder')}
              onChange={handleTicketCodeChange}
              value={ticketCode}
            />
          </div>
        </div>
        {showTickets && (
          <div className='transaction-tickets'>
            <div className='transaction-tickets-title'>
              <p>{t('manualEntry_tickets')}</p>
              <p>
                {t('manualEntry_total')}: {tickets ? tickets.length : 0}
              </p>
            </div>
            <div className='transaction-tickets-display'>
              {(tickets ?? []).map((ticket, index) => (
                <div className='transaction-ticket' key={index}>
                  <p className='transaction-ticket-id'>{ticket.ticketId}</p>
                  <p className='transaction-ticket-status'>
                    {ticket.statusMessage}
                  </p>
                </div>
              ))}
              {isTransactionLoading && type === constants.ManualEntryTransaction && (
                  <div className={'loading-icon'}>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      size='2x'
                      className={'spin-animation'}
                    />
                  </div>
                )}
            </div>
            <div className='transaction-tickets-total'>
              <p>
                {t('manualEntry_unused')}: {totalUnused}
              </p>
              <p>
                {t('manualEntry_invalid')}: {totalInvalid}
              </p>
            </div>
            <div className='transaction-tickets-submit'>
              <p>{t('manualEntry_tickets_admit')}</p>
              <input
                className='form-totalTicketSubmit'
                onChange={handleTotalTicketSubmitChange}
                placeholder={0}
                value={totalSubmitTicket}
                type='number'
              />
            </div>
          </div>
        )}
        {isTransactionResult && (
          <TransactionResultDisplay
            cName={`result-display-container ${isValid ? 'valid' : 'invalid'}`}
            result={transactionResult}
            admissionType={adType}
            isSuccess={isTransactionResultSuccess}
          />
        )}
        {isShowResult && barcode && barcode.scanReason !== undefined && (
          <ResultDisplay
              cName={`result-display-container ${
                barcode.scanResult === 1 ? 'valid' : 'invalid'
              } ${reason ? 'reason' : ''} ${
                barcode.scanCheck === constants.ScanCheckTrue ? 'scanCheck' : ''
              }
              ${voucherMode ? 'big': ''}`}
              style={
                barcode.scanResult === 1 && barcode.appResponseColor && barcode.appResponseColor !== constants.defaultSuccessColorCode
                  ? { backgroundColor: barcode.appResponseColor }
                  : {}
              }
              onClick={onProceed}
            />
        )}
          {lastScannedResult && (
              <div className='scanner-btm'>
                <div className='last-scanned-result manual-entry' onClick={viewAuditHandler}>
                    Last scanned result: {lastScannedResult}
                  {appMode === constants.AppModeOnline &&  (<><br/>[ Tap to view audit ]</>)}
                </div>
              </div>
          )}
        {admitBtn ? (
          <Button
            cName='manual-validate-btn'
              disable={admitBtnDisabledStatus}
              funct={handleSubmit(onTotalAdmit)}
              text={t('manualEntry_btnProceed')}
          />
        ) : (
            <Button
              cName='manual-validate-btn'
            disable={btnDisabledStatus}
            funct={handleSubmit(onSubmit)}
            text={t('manualEntry_btnText')}
            />
          )
        }
        {alertMessage && (
          <p className='admit-aler-msg'>{t('manualEntry_alertMessage')}</p>
        )}
      </div>
      {showConfirmBox && (
        <div className='confirm-dialog manual-admit'>
          <p>Tap confirm to admit {totalSubmitTicket} tickets</p>
          <div className='btn-group'>
            <Button
              cName='dialog-cancel'
              disable={false}
              funct={handleSubmit(onConfirmCancel)}
              text={t('manualEntry_btnCancel')}
            />
            <Button
              cName='dialog-confirm'
              disable={false}
              funct={handleSubmit(onConfirmSubmit)}
              text={t('manualEntry_btnConfirm')}
            />
          </div>
        </div>
      )}
      <audio controls ref={audioRef}>
        <track kind='captions' />
        <source src={source} type='audio/mpeg' />
      </audio>
    </form>
  );
};

export default ManualEntry;
