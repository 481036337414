import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Auth {
  static setToken(token) {
    cookies.set('token', token, { maxAge: 2147483647 });
  }

  static getToken() {
    return cookies.get('token');
  }

  static removeToken() {
    return cookies.remove('token');
  }

  static setSecret(secret) {
    localStorage.setItem('SECRET', secret);
  }

  static getSecret() {
    return localStorage.getItem('SECRET');
  }

  static removeSecret() {
    return localStorage.removeItem('SECRET');
  }
}

export default Auth;
