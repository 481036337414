import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import ScanInHandler from '../../controller/scanHandler/ScanInHandler';
import '../../styles/resultDisplay.css';
import * as constants from "../../constants/constants";

const TransactionResultDisplay = function ({ cName, result, admissionType, isSuccess }) {
  const { t } = useTranslation();
  const [admissionMsg, setAdmissionMsg] = useState('');
  const [iconClass, setIconClass] = useState(faCheckCircle);
  const [isScanIn] = useState(ScanInHandler.getIsScanInInfo());

  useEffect(() => {
    // || [constants.AppModeOffline, constants.AppModeEmergency].includes(appMode)) {
    /*  Custom message
    setIconClass(faCheckCircle);
    setAdmissionMsg(`${t('resultDisplay_entryText')}\n${result}`);
     */
    if (isSuccess == "1") {
      setIconClass(faCheckCircle);
      if (admissionType == constants.AdmissionTypeIn) {
        setAdmissionMsg(`${t('resultDisplay_entryText')}\n${result}`);
      } else {
        setAdmissionMsg(`${t('resultDisplay_exitText')}\n${result}`);
      }
    } else {
      setIconClass(faTimesCircle);
      if (admissionType == constants.AdmissionTypeIn) {
        setAdmissionMsg(`${t('resultDisplay_noentryText')}\n${result}`);
      } else {
        setAdmissionMsg(`${t('resultDisplay_noexitText')}\n${result}`);
      }
    }
  }, [result]);

  return (
    <div className={cName}>
      <div className={`result-display ${isScanIn !== 'true' && 'exit'}`}>
        <FontAwesomeIcon icon={iconClass} size='5x' />
        <p className='result-display-text'>{admissionMsg}</p>
      </div>
    </div>
  );
};

TransactionResultDisplay.propTypes = {
  cName: PropTypes.string.isRequired,
  result: PropTypes.string.isRequired,
};

export default TransactionResultDisplay;
