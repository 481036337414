import moment from "moment";

export function validateSameDayAccess(barcodeAdmitStatus, admissionRule, error) {

    const scanDate = barcodeAdmitStatus.scanDate;
    const currentDate = new Date();
    
    if (admissionRule.sameDayAccess) {
        if (barcodeAdmitStatus && barcodeAdmitStatus.scanResult === 1) {
            const validFrom = moment(scanDate).startOf("day").toDate();
            const validTo = moment(scanDate).add(admissionRule.dayAccess, "days").endOf("day").toDate();
            if (validFrom <= currentDate && currentDate <= validTo) {
                return null;
            } else {
                if (error === null || error === undefined || error.priority <= 6) {
                    return (error = { priority: 6, type: "validate_same_day_access_error" });
                } else {
                    return error;
                }
            }
        }
    }
    return null;
}
