import { Engine } from 'json-rules-engine';

export async function validateBarcodeFormat(barcode) {
  let error;
  const engine = new Engine();

  engine.addOperator('isNotNull', (factValue) => {
    if (factValue === null || factValue.length <= 0) {
      return false;
    }
    return true;
  });

  const checkBarcodeFormat = {
    conditions: {
      all: [
        {
          fact: 'barcodeFormat',
          operator: 'isNotNull',
          value: true,
        },
      ],
    },
    event: {
      type: 'barcodeFormat',
    },
    priority: 10,
    onFailure: function () {
      engine.stop();
      error = 'validate_barcode_format';
    },
  };

  engine.addRule(checkBarcodeFormat);
  await engine.run(barcode);
  return error;
}
