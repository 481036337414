import Cookies from "universal-cookie";

const cookies = new Cookies();

class ScannerUserRoleHandler {
  static setScannerUserRole(info) {
    cookies.set("SCANNER_USER_ROLE", info, { maxAge: 2147483647 });
  }

  static getScannerUserRole() {
    return cookies.get("SCANNER_USER_ROLE");
  }

  static removeScannerUserRole() {
    return cookies.remove("SCANNER_USER_ROLE");
  }
}

export default ScannerUserRoleHandler;
