class OfflineBatchHandler {
  static setOfflineBatchData(data) {
    localStorage.setItem('OFFLINE_BATCH', JSON.stringify(data));
  }

  static getOfflineBatchData() {
    const syncData = localStorage.getItem('OFFLINE_BATCH');
    return syncData;
  }

  static removeOfflineBatchData() {
    localStorage.removeItem('OFFLINE_BATCH');
  }

  static setOfflineBatchProcessData(data) {
    localStorage.setItem('OFFLINE_BATCH_PROCESS', JSON.stringify(data));
  }

  static getOfflineBatchProcessData() {
    const syncData = localStorage.getItem('OFFLINE_BATCH_PROCESS');
    return syncData;
  }

  static removeOfflineBatchProcessData() {
    localStorage.removeItem('OFFLINE_BATCH_PROCESS');
  }

  static getOfflineBatchDataObj() {
    let offlineBatchProcessData = OfflineBatchHandler.getOfflineBatchProcessData();
    let offlineBarcodesProcess = JSON.parse(offlineBatchProcessData) || [];
    let offlineBatchData = OfflineBatchHandler.getOfflineBatchData();
    let offlineBarcodes = JSON.parse(offlineBatchData) || [];
    offlineBarcodes.push(...offlineBarcodesProcess);
    OfflineBatchHandler.setOfflineBatchData(offlineBarcodes);

    // Remove and return
    OfflineBatchHandler.removeOfflineBatchProcessData();
    return offlineBarcodes;
  }
}

export default OfflineBatchHandler;
