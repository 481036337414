import React, {
  useCallback,
  useContext,
  useEffect, useRef,
  useState,
} from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { AppContext } from '../../context/appContext';
import Button from '../button/Button';
import AppModeHandler from '../../controller/appModeHandler/AppModeHandler';
import scanImg from '../../assets/images/scanborder.png';
import Configs from '../../Configs';
import * as constants from '../../constants/constants';
import '../../styles/scanner.css';
import '../../styles/barcodeAudit.css';

const BarcodeAudit = function () {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    barcode,
    setBarcode,
    setAppMode,
    setFromPath
  } = useContext(AppContext);
  const [error, setError] = useState(null);
  const scanWidth = window.innerWidth;
  const scanHeight = window.innerHeight;
  const scannerWidth = window.innerWidth * 0.8;
  const scannerHeight = window.innerHeight;
  const scanStyle = {
    backgroundImage: `url(${scanImg})`,
    height: scannerWidth,
  };
  const scanAnimateStyle = {
    top: scannerWidth,
  };
  const [scannerStatus, setScannerStatus] = useState(true);
  const [btnDisabledStatus, setBtnDisabledStatus] = useState();
  const [scanAreaClass, setScanAreaClass] = useState('scan-area-border');
  const videoRef = useRef();
  const [appMode] = useState(
    AppModeHandler.appMode() === undefined
      ? constants.AppModeOnline
      : AppModeHandler.appMode(),
  );
  const [showOfflineMsg, setShowOfflineMsg] = useState(false);

  useEffect(() => {
    // default = 1 / online
    if (
      AppModeHandler.appMode() === undefined ||
      AppModeHandler.appMode() === false
    ) {
      setAppMode(constants.AppModeOnline);
      AppModeHandler.setAppMode(constants.AppModeOnline);
    }
    setScannerStatus(true);

    if (scannerHeight < 720) {
      setScanAreaClass('scan-area-border short');
    }

      if (appMode === constants.AppModeOffline || appMode === constants.AppModeEmergency) {
          setShowOfflineMsg(true);
          setScannerStatus(false);
      } else {
          setScannerStatus(true);
      }

      // Reset the barcode
    setBarcode(undefined);

    // display the video after render the component
    // Using zxing to open camera
    const reader = new BrowserMultiFormatReader(undefined, 1000);
            let detectTimer = null;
    reader.decodeFromVideoDevice(
              undefined,
        videoRef.current,
              (result, err) => {
                if (detectTimer == null) {
                  if (result) {
                    let trimResult = result.text;
                    trimResult = trimResult.replace(/[\n\r]+/g, '');
                    detectTimer = setTimeout(() => {
                      detectTimer = null;
                    }, Configs.SCAN.RESULT_DISPLAY_DURATION);
                    zxingDetected(trimResult);
                  }
                  if (err && !(err instanceof NotFoundException)) {
                    console.error('decode err', err);
                    detectTimer = setTimeout(() => {
                      clearTimeout(detectTimer);
                      detectTimer = null;
                    }, Configs.SCAN.RESULT_DISPLAY_DURATION);
                  }
                }
              },
            );
    const historyUnlisten = history.listen(() => {
      reader.reset();
          });
    return () => {
      historyUnlisten();
    }
  }, []);

  useEffect(() => {
    if (scannerStatus) {
      setBtnDisabledStatus(false);
    } else {
      setBtnDisabledStatus(true);
    }
  }, [scannerStatus]);

  const manualEntryHandler = () => {
    history.push(Configs.BARCODE_AUDIT_MANUALENTRY.PATH);
  };

  const zxingDetected = useCallback(
    async (barcodeString) => {
      // console.log('zxingDetected');
      setScannerStatus(false);
      if (appMode === constants.AppModeOnline) {
        // valid barcode from server if connection is available
        //console.log('before fetch' + barcodeString);
        let barcode = {
          barcode: barcodeString,
        }
        setBarcode(barcode);
        viewAuditHandler();
      }
    },
    [appMode, barcode],
  );

  const viewAuditHandler = () => {
      setFromPath(Configs.BARCODE_AUDIT.PATH);
      history.push(Configs.VIEW_AUDIT.PATH);
  };

  // Go back to settings
  const backHandler = () => {
      history.push(Configs.SETTING.PATH);
  };

  return (
    <div className='video-container barcodeaudit'>
      <div className='barcodeaudit-container'>
        <div className='barcodeaudit-top'>
          <div
              className='back-btn barcodeaudit'
              onClick={backHandler}
              onKeyPress={backHandler}
              role='button'
              tabIndex='0'
          >
            {t('barcodeAudit_backText')}
          </div>
          <div className='barcodeaudit-details'>{t('barcodeAudit_headerText')}</div>
        </div>
      </div>
      {/* id interactive is default - add 'target' in inputStream for customize */}
      <video muted ref={videoRef} width={scanWidth} height={scanHeight} />
      <div id='interactive' className='viewport'>
        {/* can't display camera > hit error */}
        {error !== null && <p className='camera-error'>{error}</p>}

        {/* display camera successfully */}
            <div className='camera-overlay'>
              <span className='scan-here-text'>
                <div className={`scan-color ${scannerStatus ? '' : 'red'}`} />
                {scannerStatus
                  ? t('scan_readyScanText_1')
                  : t('scan_readyScanText_2')}
              </span>
              <div className={'scan-container'}>
                <div className={`${scanAreaClass}`} style={scanStyle}>
                  <div className='scanAnimation' style={scanAnimateStyle} />
                </div>
                <div className={'scan-info'}>
                    {showOfflineMsg && (
                        <p>{t('viewAudit_offlineMsgText')}</p>
                    )}
                </div>
              </div>
              <div className='scanner-btm'>
                <Button
                  cName='enter-barcode-btn'
                  disable={btnDisabledStatus}
                  funct={manualEntryHandler}
                  text={t('scan_btnText')}
                />
              </div>
            </div>
      </div>
    </div>
  );
};

export default withRouter(BarcodeAudit);
