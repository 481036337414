import { Engine } from 'json-rules-engine';

export async function validateBarcodeDate(barcode) {
  let error;
  const engine = new Engine();
  const currentDate = new Date().toISOString();

  engine.addOperator('isGreaterThanEqual', (factValue) => {
    if (factValue >= currentDate) return true;
    return false;
  });

  engine.addOperator('isLessThanEqual', (factValue) => {
    if (factValue <= currentDate) return true;
    return false;
  });

  const checkDate = {
    conditions: {
      all: [
        {
          fact: 'validityStart',
          operator: 'isLessThanEqual',
          value: true,
        },
        {
          fact: 'validityEnd',
          operator: 'isGreaterThanEqual',
          value: true,
        },
      ],
    },
    event: {
      type: 'barcodeDateValidity',
    },
    priority: 33,
    onFailure: function () {
      engine.stop();
      error = 'validate_barcode_valid_to_use';
    },
  };

  engine.addRule(checkDate);
  await engine.run(barcode);
  return error;
}
