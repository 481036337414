import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { AppContext } from '../../context/appContext';

export const ProtectedRoute = function ({ component: Component, ...rest }) {
  const { token, scannerUser } = useContext(AppContext);

  return (
    <Route
      render={(props) => token && scannerUser ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        )}
    />
  );
};

export const LoginProtectedRoute = function ({
  component: Component,
  ...rest
}) {
  const { scannerInfo } = useContext(AppContext);

  return (
    <Route
      {...rest}
      render={(props) => scannerInfo ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/pairScanner' }} />
        )}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  // location: PropTypes.shape({
  //   pathname: PropTypes.string.isRequired,
  // }).isRequired,
};

LoginProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  // location: PropTypes.shape({
  //   pathname: PropTypes.string.isRequired,
  // }).isRequired,
};
