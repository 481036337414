import React, { useEffect, useRef, useState } from 'react';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../styles/offlineBatchSync.css';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import SyncHandler from '../../controller/syncHandler/SyncHandler';
import Configs from '../../Configs';

const GateReport = function () {
  const history = useHistory();
  const { t } = useTranslation();
  const responseEventRef = useRef(JSON.parse(SyncHandler.getSyncData()));
  const [gateList, setGateList] = useState([]);

  const filterGates = () => {
    const gates = [];
    responseEventRef.current.forEach((event) => {
      event.admissionRules.forEach((admissionRule) => {
        admissionRule.admissionRuleGate.forEach((ruleGate) => {
          ruleGate.gates.forEach((gate) => {
            if (!gates.find((x) => x._id === gate._id)) {
              gates.push(gate);
            }
          });
        });
      });
    });
    return gates;
  };

  useEffect(() => {
    setGateList(filterGates());
  }, []);

  const onclickHandler = (gateId, gateName) => {
    console.log(gateId, gateName);
    history.push(Configs.GATE_REPORT_DETAILS.PATH, {
      gateId,
      gateName,
    });
  };

  // back to setting pg
  const backHandler = () => {
    history.push(Configs.SETTING.PATH);
  };

  return (
    <div className='offlineSync-form'>
      <div className='offlineSync-container'>
        <div className='offlineSync-top'>
          <div
            className='back-btn'
            onClick={backHandler}
            onKeyPress={backHandler}
            role='button'
            tabIndex='0'
          >
            {t('report_backText')}
          </div>
          <div className='offlineSync-details'>
            {t('gateReport_headerText')}
          </div>
        </div>
        <div className='offlineSync-form-box'>
          {gateList.map((gate) => (
            <React.Fragment key={gate._id}>
              <div
                key={gate._id}
                className='offlineSync-form-title'
                role='button'
                tabIndex='0'
                onClick={() => onclickHandler(gate._id, gate.gateName)}
                onKeyPress={() => onclickHandler(gate._id, gate.gateName)}
              >
                {gate.gateName}
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GateReport;
