class CameraHandler {
  static cameraPermissionGranted(permission) {
    localStorage.setItem('CAM_PERMISSION', permission);
  }

  // check camera permission is allowed or not
  static isCameraPermissionGranted() {
    const permission = localStorage.getItem('CAM_PERMISSION');
    if (permission === 'true') {
      return true;
    } if (permission === 'false') {
      return false;
    }
    return null;
  }
}

export default CameraHandler;
