import { Engine } from 'json-rules-engine';
import * as constants from '../../constants/constants';

export async function validateFields(facts) {
  let error;

  const checkBarcodeField = {
    conditions: {
      any: [
        {
          fact: 'barcode',
          operator: 'isNotEmpty',
          value: true,
        },
        {
          fact: 'ticketId',
          operator: 'isNotEmpty',
          value: true,
        },
      ],
    },
    event: {
      type: 'barcodeNotEmpty',
    },
    priority: 10,
    onFailure: function () {
      engine.stop();
      error = 'validate_barcode_missing';
    },
  };

  const checkGateIdField = {
    conditions: {
      all: [
        {
          fact: 'gate',
          operator: 'isNotEmpty',
          value: true,
        },
      ],
    },
    event: {
      type: 'gateIdNotEmpty',
    },
    priority: 9,
    onFailure: function () {
      engine.stop();
      error = 'validate_gate_missing';
    },
  };

  const checkScanModeField = {
    conditions: {
      all: [
        {
          fact: 'admissionType',
          operator: 'in',
          value: [constants.AdmissionTypeIn, constants.AdmissionTypeOut],
        },
      ],
    },
    event: {
      type: 'admissionTypeNotEmpty',
    },
    priority: 8,
    onFailure: function () {
      engine.stop();
      error = 'validate_admissionType_missing';
    },
  };

  const checkScannerProfileIdField = {
    conditions: {
      all: [
        {
          fact: 'scannerProfile',
          operator: 'isNotEmpty',
          value: true,
        },
      ],
    },
    event: {
      type: 'barcodeNotEmpty',
    },
    priority: 7,
    onFailure: function () {
      engine.stop();
      error = 'validate_scannerProfile_missing';
    },
  };

  const checkScannerUserIdField = {
    conditions: {
      all: [
        {
          fact: 'scannerUser',
          operator: 'isNotEmpty',
          value: true,
        },
      ],
    },
    event: {
      type: 'scannerUserIdNotEmpty',
    },
    priority: 6,
    onFailure: function () {
      engine.stop();
      error = 'validate_scannerUser_missing';
    },
  };

  const engine = new Engine(
    [
      checkBarcodeField,
      checkGateIdField,
      checkScanModeField,
      checkScannerProfileIdField,
      checkScannerUserIdField,
    ],
    {
      allowUndefinedFacts: true,
    },
  );

  engine.addOperator('isNotEmpty', (factValue) => {
    try {
      if (factValue.length > 0) return true;
      return false;
    } catch (error) {
      return false;
    }
  });

  await engine.run(facts);
  return error;
}
