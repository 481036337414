import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../context/appContext';
import { Button as MaterialButton } from '@material-ui/core';
import Button from '../button/Button';
import AppModeHandler from '../../controller/appModeHandler/AppModeHandler';
import Configs from '../../Configs';
import * as constants from '../../constants/constants';
import '../../styles/manualEntry.css';
import '../../styles/barcodeAudit.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


const BarcodeAuditManualEntry = function () {
  const { auditBarcode, setAuditBarcode, auditTicketID, setAuditTicketID, setFromPath } = useContext(AppContext);
  const history = useHistory();
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const [ticketCode, setTicketCode] = useState('');
  const [isDisableBtn, setIsDisableBtn] = useState(true);
  const [appMode] = useState(AppModeHandler.appMode());
  const [type, setType] = useState(constants.ManualEntryTicket);
  const [showOfflineMsg, setShowOfflineMsg] = useState(false);

  useEffect(() => {
    // Disable when no offline
    barcodeDefault(false);
    if (appMode === constants.AppModeOffline || appMode === constants.AppModeEmergency) {
      setIsDisableBtn(true);
      setShowOfflineMsg(true);
    }
  }, []);

  // go to barcode audit
  const backHandler = () => {
    history.push(Configs.BARCODE_AUDIT.PATH);
  };

  const handleTicketCodeChange = (event) => {
    // Reset fields to search again
    setIsDisableBtn(true);

    setTicketCode(event.target.value.trim());
    if (event.target.value.trim()) {
      setIsDisableBtn(false);
    } else {
      setIsDisableBtn(true);
    }
  };

  const typeChange = (type) => {
    const typeBtns = document.querySelectorAll('.manual-type-button');
    typeBtns.forEach((btn) => {
      if (btn.classList.contains(type)) {
        btn.classList.add('active');
      } else {
        btn.classList.remove('active');
      }
    });
    setType(type);
  };

  const barcodeDefault = (makeDefault) => {
    const typeBtns = document.querySelectorAll('.manual-type-button');
    typeBtns.forEach((btn) => {
      if (makeDefault && btn.classList.contains(constants.ManualEntryBarcode)) {
        btn.classList.add('active');
        setType(constants.ManualEntryBarcode);
      } else if (!makeDefault && btn.classList.contains(constants.ManualEntryTicket)) {
        btn.classList.add('active');
        setType(constants.ManualEntryTicket);
      } else {
        btn.classList.remove('active');
      }
    });
  };

  const onSubmit = useCallback(
    async (data) => {
      setIsDisableBtn(true);

      // Reset values first
      setAuditBarcode();
      setAuditTicketID();
      if (appMode === constants.AppModeOnline) {
        if (type === constants.ManualEntryTicket) {
          setAuditTicketID(data.ticketcode);
        } else {
          setAuditBarcode(data.ticketcode);
        }
        viewAuditHandler();
      }
    },

    [appMode, auditBarcode, auditTicketID, type, ticketCode],
  );

  const clearManualInput = () => {
    setTicketCode('');
    setIsDisableBtn(true);
  };

  const viewAuditHandler = () => {
    setFromPath(Configs.BARCODE_AUDIT_MANUALENTRY.PATH);
    history.push(Configs.VIEW_AUDIT.PATH);
  };

  return (
    <form className='manual-form'>
      <div className='manual-container'>
        <div className='manual-top'>
          <div
            className='back-btn manual-entry'
            onClick={backHandler}
            onKeyPress={backHandler}
            role='button'
            tabIndex='0'
          >
            {t('manualEntry_backText')}
          </div>
          <div className='barcodeaudit-details manual-entry'>{t('barcodeAudit_headerText')}</div>
        </div>
        <div className='manual-type-container'>
          <div className='manual-type-selection'>
            <MaterialButton
              disableElevation
              variant='contained'
              className={`manual-type-button ${constants.ManualEntryTicket}`}
              onClick={() => typeChange(constants.ManualEntryTicket)}
            >
              {t('manualEntry_ticket_id')}
            </MaterialButton>
            <MaterialButton
              disableElevation
              className={`manual-type-button ${constants.ManualEntryBarcode}`}
              variant='contained'
              onClick={() => typeChange(constants.ManualEntryBarcode)}
            >
              {t('manualEntry_barcode')}
            </MaterialButton>
          </div>
        </div>
        <div className='manual-form-box'>
          <div className='manual-ticket-group'>
            {ticketCode.length > 0 && (
              <FontAwesomeIcon
                icon={faTimes}
                className='manual-input-clear'
                onClick={clearManualInput}
              />
            )}
            <input
              {...register('ticketcode', { required: true })}
              name='ticketcode'
              id='ticketcode'
              className='form-ticketcode'
              placeholder={t('manualEntry_placeholder')}
              onChange={handleTicketCodeChange}
              value={ticketCode}
            />
          </div>
        </div>
        {showOfflineMsg && (
            <p>{t('viewAudit_offlineMsgText')}</p>
        )}
        <Button
            cName='manual-validate-btn'
            disable={isDisableBtn}
            funct={handleSubmit(onSubmit)}
            text={t('barcodeAudit_getAudit')}
        />
      </div>
    </form>
  );
};

export default BarcodeAuditManualEntry;
