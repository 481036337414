import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SyncHandler from '../../controller/syncHandler/SyncHandler';
import EventDataHandler from '../../controller/eventHandler/EventHandler';
import GateDataHandler from '../../controller/eventHandler/GateHandler';
import Configs from '../../Configs';
import '../../styles/event.css';
import FetchLatestConfigHandler from '../../controller/eventHandler/FetchLatestConfigHandler';

const SelectEvent = function ({ history }) {
  const historyy = useHistory();
  const { t } = useTranslation();
  const [eventListNode, setEventListNode] = useState(null);
  const [gateListNode, setGateListNode] = useState('');
  const [selectedEvent, setSelectedEvent] = useState(
    EventDataHandler.getEventInfo(),
  );
  const [selectedGate, setSelectedGate] = useState(
    GateDataHandler.getGateInfo(),
  );
  const [selectedEventName, setSelectedEventName] = useState(
    EventDataHandler.getEventNameInfo(),
  );
  const responseEventRef = useRef(JSON.parse(SyncHandler.getSyncData()));

  useEffect(() => {
    setEventListNode(
      (responseEventRef.current || []).map((item) => {
        const isTick = item._id === selectedEvent;
        const tickNode = isTick ? <FontAwesomeIcon icon={faCheck} /> : null;
        return (
          <div
            key={`event${item._id}`}
            className='event-form-title'
            onClick={() => onClickEvent(item._id, item.eventName)}
            onKeyPress={() => onClickEvent(item._id, item.eventName)}
            role='button'
            tabIndex='0'
          >
            {item.eventName}
            {tickNode}
          </div>
        );
      }),
    );
  }, [selectedEvent]);

  useEffect(() => {
    let selectedEventGate;
    // if (
    //   responseEventRef.current != null ||
    //   responseEventRef.current != undefined
    // ) {
    //   selectedEventGate = responseEventRef.current.find(
    //     (x) => x._id === selectedEvent,
    //   );
    // }
    let gateList = [];
    let singleGateType = null;
    // if (selectedEventGate) {
    const gateNode = (responseEventRef.current || []).map((event) => {
      const gates = (event.admissionRules || []).map((rulesItem) => {
          const gateContent = (rulesItem.admissionRuleGate || []).map(
            (gateItem) => {
              const gateExist = gateList.find(
                (gate) => gate.gateTypeId === gateItem.gateTypeId,
              );
              if (!gateExist) {
                gateList.push(gateItem);
              singleGateType = gateItem;
                const isTick = gateItem.gateTypeId === selectedGate;
                const tickNode = isTick ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : null;
                return (
                  <div
                    key={`gate${gateItem._id}`}
                  onClick={() =>
                    onClickGate(gateItem.gateTypeId, gateItem.gateName)
                  }
                  onKeyPress={() =>
                    onClickGate(gateItem.gateTypeId, gateItem.gateName)
                  }
                    role='button'
                    tabIndex='0'
                    className='event-form-title'
                  >
                    {gateItem.gateName}
                    {tickNode}
                  </div>
                );
              }
            },
          );
          return gateContent;
      });
      return gates;
    });

    // Handle for single gate to be auto-selected only when coming from sync path
    if (history.location.state.from === Configs.SYNC.PATH && gateList.length == 1 && singleGateType) {
      setSelectedGate(singleGateType.gateTypeId);
      EventDataHandler.setEventInfo(selectedEvent);
      EventDataHandler.setEventNameInfo(selectedEventName);
      GateDataHandler.setGateInfo(singleGateType.gateTypeId);
      GateDataHandler.setGateNameInfo(singleGateType.gateName);

      historyy.push(Configs.SCAN.PATH);
    }

      setGateListNode(
        <>
        {/* <div className='event-top'>
            <div className='gate-details'>{t('event_selectGateText')}</div>
          </div> */}
          <div className='event-form-gate'>{gateNode}</div>
        </>,
      );
    // }
  }, [selectedEvent, selectedGate]);

  const onClickGate = (id, name) => {
    setSelectedGate(id);
    EventDataHandler.setEventInfo(selectedEvent);
    EventDataHandler.setEventNameInfo(selectedEventName);
    GateDataHandler.setGateInfo(id);
    GateDataHandler.setGateNameInfo(name);
    FetchLatestConfigHandler.removeModalLastDisplayDate();

    if (EventDataHandler.getEventInfo() && GateDataHandler.getGateInfo()) {
      setTimeout(
        () => historyy.push(Configs.SCAN.PATH),
        Configs.EVENT.TIMEOUT_DURATION,
      );
    }
  };

  const onClickEvent = (id, name) => {
    if (selectedEvent !== id) {
      setSelectedEvent(id);
      setSelectedEventName(name);
      setSelectedGate();
    }
  };

  // go to scanner > Scanner not ready
  const backHandler = () => {
    try {
      if (history.location.state.from === Configs.SYNC.PATH) {
        historyy.push(Configs.SCAN.PATH);
      } else {
        historyy.push(Configs.SETTING.PATH);
      }
    } catch (e) {
      historyy.push(Configs.SCAN.PATH);
    }
  };

  return (
    <div className='event-form'>
      <div className='event-container'>
        <div className='event-top'>
          <div
            className='back-btn'
            onClick={backHandler}
            onKeyPress={backHandler}
            role='button'
            tabIndex='0'
          >
            {t('event_backText')}
          </div>
          <div className='event-details'>{t('event_selectGateText')}</div>
        </div>
        {/* <div className='event-form-box'>{eventListNode}</div> */}
        {gateListNode}
      </div>
    </div>
  );
};

// props.history.location.state.from
SelectEvent.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        from: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default SelectEvent;
