import Cookies from 'universal-cookie';

const cookies = new Cookies();

class ScanInHandler {
  static setIsScanInInfo(info) {
    cookies.set('IS_IN', info,{ maxAge: 2147483647 });
  }

  static getIsScanInInfo() {
    return cookies.get('IS_IN');
  }

  static removeIsScanInInfo() {
    return cookies.remove('IS_IN');
  }
}

export default ScanInHandler;
