import GateDataHandler from "../controller/eventHandler/GateHandler";
import SyncDataHandler from "../controller/syncHandler/SyncHandler";

export const getEventsFromGateId = () => {
    const events = JSON.parse(SyncDataHandler.getSyncData());
    const gateId = GateDataHandler.getGateInfo();

    if(!gateId) return
    return events
        .map(event => {
            const eventId = event._id;
            const updatedDate = event.updatedDate;

            const hasMatchingGate = (event.admissionRules || []).some(rule => 
                (rule.admissionRuleGate || []).some(gate => 
                    (gate.gates || []).some(g => g._id === gateId)
                )
            );

            return hasMatchingGate ? { _id: eventId, updatedDate } : null;
        })
        .filter(event => event !== null);
}
