import React from 'react';
import splashIcon1 from '../../assets/images/splash-icon-1.png';
import splashIcon2 from '../../assets/images/splash-icon-2.png';
const SplashScreen = function () {
  return (
    <>
      <div className='splash-screen-background'>
        <div className='splash-screen-container'>
          <img src={splashIcon1} />
          <img src={splashIcon2} />
        </div>
      </div>
    </>
  );
};

export default SplashScreen;
