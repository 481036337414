import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import '../../styles/report.css';

const ScanCountReportDetail = function ({ history }) {
  const historyy = useHistory();
  const { t } = useTranslation();
  const [date, setDate] = useState('');
  const [resultList, setResultList] = useState([]);

  useEffect(() => {
    if (history.location.state !== undefined) {
      setDate(history.location.state.data.key);
      setResultList(history.location.state.data.data);
    }
  }, []);
  
  const backHandler = () => {
    historyy.goBack();
  };

  return (
    <div className='report-form'>
      <div className='report-container'>
        <div className='report-top'>
          <div
            className='back-btn'
            onClick={backHandler}
            onKeyPress={backHandler}
            role='button'
            tabIndex='0'
          >
            {t('report_backText')}
          </div>
          <div className='report-details'>
            {t('scanCountReportDetail_headerText')}
          </div>
        </div>
        <div className='scan-count-form-box'>
              <div className='scan-count-date'>{date}</div>
              
          {resultList.map(result => (
            <>
              <div className='scan-count-title'>
                {result.key}
              </div>
              <div className='scan-count-value'>{result.data}</div>
              
            </>
          ))}
            </div>
      </div>
    </div>
  );
};

// props.history.location.state.from
ScanCountReportDetail.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        gateId: PropTypes.string,
        gateName: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default ScanCountReportDetail;
