import * as constants from '../../constants/constants';

export async function validateScanningTime(
  admissionRule,
  admissionType,
  session,
  error,
) {
  if (admissionType === constants.AdmissionTypeIn) {
    return await validateEntryTime(admissionRule, session, error);
  }
  if (admissionType === constants.AdmissionTypeOut) {
    return await validateExitTime(admissionRule, session, error);
  }
}

async function validateEntryTime(admissionRule, session, error) {
  const currentTime = new Date();
  let startDate;
  let endDate;
  if (admissionRule.entryStart && admissionRule.entryStart !== null && admissionRule.entryEnd && admissionRule.entryEnd !== null) {
    startDate = new Date(admissionRule.entryStart);
    endDate = new Date(admissionRule.entryEnd);
  } else {
    if (admissionRule.entryRelativeStart === null && admissionRule.entryRelativeEnd === null) {
      startDate = new Date(session.startDate);
      endDate = new Date(session.startDate);
    } else {
      const dateStart = new Date(session.startDate);
      const dateEnd = new Date(session.startDate);
      startDate = new Date(
        dateStart.setMinutes(
          dateStart.getMinutes() + admissionRule.entryRelativeStart,
        ),
      );
      endDate = new Date(
        dateEnd.setMinutes(
          dateEnd.getMinutes() + admissionRule.entryRelativeEnd,
        ),
      );
    }
  }
  if (startDate <= currentTime && endDate <= currentTime) {
    if (error === null || error === undefined || error.priority <= 3) {
      return (error = {
        priority: 3,
        type: 'validate_admission_time_invalid',
      });
    } else {
      return error;
    }
  } else if (startDate >= currentTime && endDate >= currentTime) {
    if (error === null || error === undefined || error.priority <= 3) {
      return (error = {
        priority: 3,
        type: 'validate_admission_time_invalid',
      });
    } else {
      return error;
    }
  }
  return null;
}

async function validateExitTime(admissionRule, session, error) {
  const currentTime = new Date();
  let startDate;
  let endDate;
  if (admissionRule.exitStart && admissionRule.exitStart !== null && admissionRule.exitEnd && admissionRule.exitEnd !== null) {
    startDate = new Date(admissionRule.exitStart);
    endDate = new Date(admissionRule.exitEnd);
  } else {
    if (admissionRule.exitRelativeStart === null && admissionRule.exitRelativeEnd === null) {
      startDate = new Date(session.endDate);
      endDate = new Date(session.endDate);
    } else {
      const dateStart = new Date(session.endDate);
      const dateEnd = new Date(session.endDate);
      startDate = new Date(
        dateStart.setMinutes(
          dateStart.getMinutes() + admissionRule.exitRelativeStart,
        ),
      );
      endDate = new Date(
        dateEnd.setMinutes(
          dateEnd.getMinutes() + admissionRule.exitRelativeEnd,
        ),
      );
    }
  }
  if (startDate <= currentTime && endDate <= currentTime) {
    if (error === null || error === undefined || error.priority <= 3) {
      return (error = { priority: 3, type: 'validate_exit_time_invalid' });
    } else {
      return error;
    }
  } else if (startDate >= currentTime && endDate >= currentTime) {
    if (error === null || error === undefined || error.priority <= 3) {
      return (error = { priority: 3, type: 'validate_exit_time_invalid' });
    } else {
      return error;
    }
  }
  return null;
}
