import React, { useContext, useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { fetchApi } from '../../AjaxUtil';
import Configs from '../../Configs';
import { AppContext } from '../../context/appContext';
import OfflineBatchHandler from '../../controller/syncHandler/OfflineBatchHandler';
import PairScannerAuth from '../../controller/authHandler/PairScannerAuthHandler';
import Auth from '../../controller/authHandler/AuthHandler';
import PairScannerNameHandler from '../../controller/authHandler/PairScannerNameHandler';
import EventDataHandler from '../../controller/eventHandler/EventHandler';
import GateDataHandler from '../../controller/eventHandler/GateHandler';
import ScannerUserHandler from '../../controller/authHandler/ScannerUserHandler';
import AppModeHandler from '../../controller/appModeHandler/AppModeHandler';
import ToastMessage from '../resultDisplay/ToastMessage';
import { useHistory } from 'react-router-dom';
import * as constants from "../../constants/constants";
import ScanInHandler from '../../controller/scanHandler/ScanInHandler';
import { useErrorHanlder } from '../../context/errorContext';

const Reset = function () {
  const history = useHistory();
  const { t } = useTranslation();
  const { setScannerInfo, scannerInfo, setConnection } = useContext(AppContext);
  const [progressPercent, setProgressPercent] = useState(0);
  const [responseSucc, setResponseSucc] = useState(true);
  const [syncAlertMsg, setSyncAlertMsg] = useState();
  const [syncAlertStatus, setSyncAlertStatus] = useState();
  const [isShowMsg, setIsShowMsg] = useState(false);
  const [isSyncComplete, setIsSyncComplete] = useState(false);
  const [offlineBarcodes, setOfflineBarcodes] = useState(
      OfflineBatchHandler.getOfflineBatchDataObj()
  );
  const { showErrorNotification } = useErrorHanlder()
  let pruneBarcodes = [];

  useEffect(() => {
    if (isSyncComplete) setProgressPercent(100);
  }, [isSyncComplete]);

  useEffect(() => {
      if (progressPercent === 100) {
        setSyncAlertStatus('success sync');
        setSyncAlertMsg(t(Configs.RESET_DEVICE.SUCCESS_MSG));
        setIsShowMsg(true);
      document.querySelector('#reset-progress-percent').classList.add('right');
    }
  }, [progressPercent]);

  useEffect(() => {
      if (progressPercent === 0) {
        setProgressPercent(25);
      } else if (progressPercent === 25) {
        syncOfflineBarcodes();
      } else if (progressPercent === 75) {
        setIsSyncComplete(true);
      } else if (progressPercent === 100) {
    }
  }, [progressPercent, responseSucc]);

  async function syncOfflineBarcodes() {
    if (offlineBarcodes !== null && offlineBarcodes.length > 0) {
      let unSyncedBarcodes = [];
      for (const barcode of offlineBarcodes) {
        if (!barcode.synced) {
          if (barcode.recordId === undefined || barcode.barcode === undefined || barcode.admissionType === undefined
              || barcode.gateId === undefined || barcode.scanResult === undefined || barcode.scanDate === undefined
              || barcode.scanBy === undefined || barcode.scanCheck === undefined || barcode.scanMode === undefined
              || barcode.scannerProfile === undefined) {
            // Skip
          } else {
            unSyncedBarcodes.push(barcode);
          }
        }
      }
      if (unSyncedBarcodes.length > 0) {
        pruneBarcodes = [];
        pruneBarcodes.push(...unSyncedBarcodes);

        const params = {
          offlineBatch: unSyncedBarcodes,
        };
        await fetchApi(
          Configs.HEALTHCHECK.OFFLINE_SYNC_API_METHOD,
          Configs.HEALTHCHECK.OFFLINE_SYNC_API_PATH,
          onSuccessCallback,
          params,
          onErrorCallback,
        );
      } else {
        setProgressPercent(50);
        logoutUnpairDevice();
      }
    } else {
      setProgressPercent(50);
      logoutUnpairDevice();
    }
  }

  const onSuccessCallback = (response) => {
    setResponseSucc(true);
    setProgressPercent(50);
    const failedSync = response.failedSync;
    let offlineTempData = offlineBarcodes;

    // update offlineBatchData sync info
    if (failedSync.length !== 0) {
      // sync fail
      Object.keys(offlineTempData).forEach((obj, index) => {
        Object.keys(pruneBarcodes).forEach((preobj, preindex) => {
          let isMatchRecordId = false;
      failedSync.forEach((data) => {
            if (data === offlineTempData[index].recordId) {
              isMatchRecordId = true;
            }
          });
          const isSyncFalse = offlineTempData[index].synced === false;
          if (!isMatchRecordId && isSyncFalse && pruneBarcodes[preindex].recordId == offlineTempData[index].recordId) {
            offlineTempData[index].synced = true;
          }
        });
      });
    } else {
      Object.keys(offlineTempData).forEach((obj, index) => {
        Object.keys(pruneBarcodes).forEach((preobj, preindex) => {
          const isSyncFalse = offlineTempData[index].synced === false;
          if (isSyncFalse && pruneBarcodes[preindex].recordId == offlineTempData[index].recordId) {
            //console.log("...updated" + pruneBarcodes[preindex].recordId );
        offlineTempData[index].synced = true;
          }
        });
      });
    }
    OfflineBatchHandler.setOfflineBatchData(offlineTempData);
    /* setOfflineBarcodes(offlineTempData); */
    logoutUnpairDevice();
  };

  async function logoutUnpairDevice() {
    const scannerProfileId = PairScannerAuth.getScannerInfo();
    Configs.PAIRSCANNER.UNPAIR_API_PATH = `scannerProfile/${scannerProfileId}/unpair/`;
    const paramProfile = {
      scannerProfileId: scannerInfo,
    };
    // Exception handling for unpair when token is not present
    if (Auth.getToken() == null) {
      Auth.setToken(constants.GuestJWT(Auth.getSecret()));
    }
    await fetchApi(
      Configs.PAIRSCANNER.UNPAIR_API_METHOD,
      Configs.PAIRSCANNER.UNPAIR_API_PATH,
      onSuccessCallbackUnpair,
      paramProfile,
      onUnpairErrorCallback,
    );
  }

  const onErrorCallback = (err, status, message) => {
    setSyncAlertStatus('fail sync');
    setSyncAlertMsg(t(Configs.RESET_DEVICE.FAIL_MSG));
    setIsShowMsg(true);
    setTimeout(() => history.push(Configs.SETTING.PATH), 2000);
    showErrorNotification({message: `${status}: ${message}` || err});
  };

  const onUnpairErrorCallback = (err, status, message) => {
    setSyncAlertStatus('fail sync');
    setSyncAlertMsg(t(Configs.RESET_DEVICE.FAIL_MSG));
    setIsShowMsg(true);
    setTimeout(() => history.push(Configs.SETTING.PATH), 2000);
    showErrorNotification({message: `${status}: ${message}` || err});
  };

  const onSuccessCallbackUnpair = () => {
    setProgressPercent(75);
    localStorage.clear();
    PairScannerAuth.removeScannerInfo();
    PairScannerAuth.removeScannerToken();
    PairScannerNameHandler.removeScannerName();
    EventDataHandler.removeEventInfo();
    EventDataHandler.removeEventNameInfo();
    GateDataHandler.removeGateInfo();
    GateDataHandler.removeGateNameInfo();
    ScannerUserHandler.removeScannerUser();
    AppModeHandler.removeAppMode();
    ScanInHandler.setIsScanInInfo(true)
    setConnection(true);
    Auth.removeToken();
    setScannerInfo(null);
    setTimeout(
      () => history.push(Configs.PAIRSCANNER.PATH),
      Configs.PAIRSCANNER.SUCCESS_DISPLAY_DURATION,
    );
  };

  return (
    <>
      <div className='reset-container'>
        <p className='reset-title'>{t('sync_title')}</p>
        <p className='reset-desc'>{t('reset_desc')}</p>
        <ProgressBar now={progressPercent} />
        <p className='reset-progress-percent' id='reset-progress-percent'>
          {progressPercent}
          {t('sync_percentCompleteText')}
        </p>
      </div>
      {isShowMsg && <ToastMessage cName={syncAlertStatus} msg={syncAlertMsg} />}
    </>
  );
};

export default Reset;
