import Cookies from 'universal-cookie';

const cookies = new Cookies();

class EventDataHandler {
  // event id
  static setEventInfo(info) {
    cookies.set('EVENT', info);
  }

  static getEventInfo() {
    return cookies.get('EVENT');
  }

  static removeEventInfo() {
    return cookies.remove('EVENT');
  }

  // event name
  static setEventNameInfo(info) {
    cookies.set('EVENT_NAME', info);
  }

  static getEventNameInfo() {
    return cookies.get('EVENT_NAME');
  }

  static removeEventNameInfo() {
    return cookies.remove('EVENT_NAME');
  }
}

export default EventDataHandler;
