import Cookies from 'universal-cookie';

const cookies = new Cookies();

class AppModeHandler {
  static setAppMode(info) {
    cookies.set('MODE', info);
  }

  static appMode() {
    return cookies.get('MODE');
  }

  static removeAppMode() {
    return cookies.remove('MODE');
  }
}

export default AppModeHandler;
