import CryptoJS from 'crypto-js';

export const decryptBarcode = async (barcode, encryptionKey) => {
  // Decrypt
  try {
    var value = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Base64.parse(barcode),
    );
  var key = CryptoJS.enc.Utf8.parse(encryptionKey);
  var iv = CryptoJS.enc.Utf8.parse(encryptionKey.substring(0, 17));
    var decrypted = CryptoJS.AES.decrypt(value, key, { iv: iv });
  var originalText = decrypted.toString(CryptoJS.enc.Utf8);
  if (originalText.length > 0) {
    const data = {
      eventCode: originalText.substring(0, 8),
      sessionCode: originalText.substring(8, 12),
      categoryCode: originalText.substring(12, 14),
      areaCode: originalText.substring(14, 16),
      ticketType: originalText.substring(16, 18),
      ticketNo: originalText.substring(18, 30),
      reprintCount: originalText.substring(30),
      decrypted: true,
    };
    return {
      eventCode: data.eventCode,
      sessionCode: data.sessionCode,
      ticketId: data.ticketNo,
      barcode: barcode,
      decrypted: true,
      barcodeAttributes: [
        {
          attributeId: data.areaCode,
          attributeType: 'AREA',
          attributeName: data.areaCode,
        },
        {
          attributeId: data.categoryCode,
          attributeType: 'PRICE_CATEGORY',
          attributeName: data.categoryCode,
        },
        {
          attributeId: data.ticketType,
          attributeType: 'TICKET_TYPE',
          attributeName: data.ticketType,
        },
      ],
    };
    }
  } catch (error) {
    console.log(error.message);
  }
};
