import React, {
    createContext,
    useContext,
    useState,
    useCallback
  } from 'react';
import ErrorToastManager from '../components/errorToastManager/ErrorToastManager';
  
  const initialState = {
    errorProps: [],
    showErrorNotification: (errorObject) => [],
  };
  
  const ErrorContext = createContext(initialState);
  const Provider = ErrorContext.Provider;
  
  const ErrorProvider = (props) => {
    const { children } = props;
    const [errorProps, setErrorProps] = useState([]);
    
    const showErrorNotification = useCallback((errorObject) => {
      const id = Date.now();
  
      setErrorProps((prevErrorProps) => [
        ...prevErrorProps,
        {
          ...errorObject,
          id,
          autoClose: true,
          autoCloseTime: (prevErrorProps.length + 1) * 5000,
        },
      ]);
    }, []);
  
    const removeErrorToast = useCallback((id) => {
      setErrorProps((prevErrorProps) =>
        prevErrorProps.filter((error) => error.id !== id)
      );
    }, []);
  
    return (
      <Provider
        value={{
          errorProps,
          showErrorNotification
        }}
      >
        {children}
        <ErrorToastManager errorToasts={errorProps} onErrorToastRemove={removeErrorToast} />
      </Provider>
    );
  };
  
  const useErrorHanlder = () => {
    const {
      errorProps,
      showErrorNotification
    } = useContext(ErrorContext);
    return {
      errorProps,
      showErrorNotification
    };
  };
  
  export { ErrorProvider, useErrorHanlder };
  