import Cookies from 'universal-cookie';

const cookies = new Cookies();

class ScannerUserHandler {
  static setScannerUser(info) {
    cookies.set('SCANNER_USER', info, { maxAge: 2147483647 });
  }

  static getScannerUser() {
    return cookies.get('SCANNER_USER');
  }

  static removeScannerUser() {
    return cookies.remove('SCANNER_USER');
  }
}

export default ScannerUserHandler;
