import Cookies from 'universal-cookie';

const cookies = new Cookies();

class PairScannerNameHandler {
  static setScannerName(info) {
    cookies.set('SCANNER_NAME', info, { maxAge: 2147483647 });
  }

  static getScannerName() {
    return cookies.get('SCANNER_NAME');
  }

  static removeScannerName() {
    return cookies.remove('SCANNER_NAME');
  }
}

export default PairScannerNameHandler;
