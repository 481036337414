
export async function validateUniqueGate(gate, lastBarcodeAudit, error) {
    if (lastBarcodeAudit) {
        if (lastBarcodeAudit.gateId.toString() === gate.toString()) {
            if (error === null || error === undefined || error.priority <= 5) {
                return (error = { priority: 6, type: "validate_unique_gate_error" });
            }
            return error;
        }
    }
    return null;
}
