import React, { useState, useEffect } from 'react';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import OfflineBatchHandler from '../../controller/syncHandler/OfflineBatchHandler';
import Configs from '../../Configs';
import '../../styles/offlineBatchSync.css';

const offlineBatchSync = function () {
  const history = useHistory();
  const { t } = useTranslation();
  const [dates, setDates] = useState([]);

  useEffect(() => {
    let offlineBatchData = OfflineBatchHandler.getOfflineBatchDataObj();

    if (offlineBatchData) {
      offlineBatchData = offlineBatchData.filter((data) => moment().diff(moment(data.scanDate), 'days') < Configs.OFFLINE_BATCH_SYNC.ARCHIVAL_DURATION_BY_DAYS || data.synced === false);
      OfflineBatchHandler.setOfflineBatchData(offlineBatchData);

      const batchSync = [];
      const set = new Set();
        offlineBatchData.forEach((data) => {
        const dataDates = moment(data.scanDate).format('D MMM YYYY');
        if (!set.has(dataDates)) {
          set.add(dataDates)
        }
      });

      const uniqueDate = Array.from(set);
      uniqueDate.forEach((data) => {
        const dataDates = offlineBatchData.filter(
          (x) => moment(x.scanDate).format('D MMM YYYY') === data,
        );
        const isCompleted = dataDates.map((x) => x.synced === true).filter((x) => x === false).length === 0;
        batchSync.push({
          date: data,
          synced: isCompleted,
        });
      });

      setDates(batchSync);
    }
  }, []);

  const onclickHandler = (item) => {
    history.push(Configs.OFFLINE_BATCH_SYNC_DETAILS.PATH, { dataDate: item });
  };

  const backHandler = () => {
    history.push(Configs.SETTING.PATH);
  };

  return (
    <div className='offlineSync-form'>
      <div className='offlineSync-container'>
        <div className='offlineSync-top'>
          <div
            className='back-btn'
            onClick={backHandler}
            onKeyPress={backHandler}
            role='button'
            tabIndex='0'
          >
            {t('offlineBatchSync_backText')}
          </div>
          <div className='offlineSync-details'>
            {t('offlineBatchSync_headerText')}
          </div>
        </div>
        <div className='offlineSync-form-box'>
          {dates.map((item) => (
            <div
              key={item.date}
              className='offlineSync-form-title'
              role='button'
              tabIndex='0'
              onClick={() => onclickHandler(item.date)}
              onKeyPress={() => onclickHandler(item.date)}
            >
              {item.date}
              <FontAwesomeIcon icon={faAngleRight} />
              {item.synced ? (
                <span className='offlineSync-complete'>
                  {t('offlineBatchSync_syncCompleteText')}
                </span>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default offlineBatchSync;
